import { updateObject } from "../../helpers/index";
import * as actionTypes from "../actions/actionTypes";

const initialState = {
  products: null,
  products_error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PRODUCTS_SET:
      return fetchProductsSuccess(state, action);
    case actionTypes.PRODUCTS_FETCH_FAIL:
      return fetchProductsFail(state, action);
    default:
      return state;
  }
};

// =========== MY PRODUCTS FREE
const fetchProductsSuccess = (state, action) => {
  return updateObject(state, {
    products: action.data,
  });
};

const fetchProductsFail = (state, action) => {
  return updateObject(state, {
    products_error: action.error,
  });
};

export default reducer;
