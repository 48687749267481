import { updateObject } from "../../helpers/index";
import * as actionTypes from "../actions/actionTypes";

const initialState = {
  patients_purge: null,
  patients_purge_error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PATIENTS_PURGE_SET:
      return fetchPatientsPurgeSuccess(state, action);
    case actionTypes.PATIENTS_PURGE_FETCH_FAIL:
      return fetchPatientsPurgeFail(state, action);
    default:
      return state;
  }
};

// =========== MY PATIENTS PURGE
const fetchPatientsPurgeSuccess = (state, action) => {
  return updateObject(state, {
    patients_purge: action.data,
  });
};

const fetchPatientsPurgeFail = (state, action) => {
  return updateObject(state, {
    patients_purge_error: action.error,
  });
};

export default reducer;
