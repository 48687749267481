import { LS_DEFAULT_LANGUAGE } from "../constants/storage";
import { DEFAULT_LANGUAGE } from "../constants/defaults";
import i18n from "../i18n";

export const updateObject = (oldBject, updatedProperties) => {
  return {
    ...oldBject,
    ...updatedProperties,
  };
};
// [important] HAVE TO BE "!="  becouse its compare null AND undefined
// [important] "!==" its compare ONLY IF null
export const checkExist = (item) => {
  return item != null;
};
export const checkExistAndNotBlack = (item) => {
  return item != null && item !== "";
};

export const checkValidLink = (item) => {
  return item != null && (item === true || item === false);
};

// Set default language
export const setLanguage = (lang) => {
  if (!lang) {
    lang = localStorage.getItem(LS_DEFAULT_LANGUAGE)
      ? localStorage.getItem(LS_DEFAULT_LANGUAGE)
      : DEFAULT_LANGUAGE;
  }
  localStorage.setItem(LS_DEFAULT_LANGUAGE, lang);
  i18n.changeLanguage(lang);
};

// Set default language
export const getLanguage = () => {
  let lang = LS_DEFAULT_LANGUAGE;
  if (localStorage.getItem(LS_DEFAULT_LANGUAGE))
    lang = localStorage.getItem(LS_DEFAULT_LANGUAGE);
  else localStorage.setItem(LS_DEFAULT_LANGUAGE, lang);
  return lang;
};

export const isActiveLanguage = (lang) => {
  return getLanguage() === lang;
};

export const getKeyGen = (key = "") => {
  key = simplestText(key);
  return key + Math.random() + new Date().getMilliseconds();
};

/**
 * It's return DD/MM/YYYY
 */
export const setDateToDB = (date) => {
  let content = "";
  if (date) {
    date = date.replace("-", "/");
    date = date.replace("-", "/");

    const temp_date = date.split("/");
    if (temp_date[0].length > temp_date[2].length) {
      content = temp_date[0] + "/" + temp_date[1] + "/" + temp_date[2];
    } else {
      content = temp_date[2] + "/" + temp_date[1] + "/" + temp_date[0];
    }
  }
  return content;
};

export const getFormatedDate2 = (date) => {
  let content = "";
  if (date) {
    try {
      date = date.replace("-", "/");
    } catch {}
    const formatedDate = new Date(date);
    let day = formatedDate.getDate();
    day = day < 10 ? "0" + day : day;
    // getMonth() return (0 - 11)
    let month = formatedDate.getMonth() + 1;
    month = month < 10 ? "0" + month : month;
    //  content =  day + "/" + month + "/" + formatedDate.getFullYear();
    content = day + "/" + month + "/" + formatedDate.getFullYear();
  }
  return content;
};

export const getFormatedDate = (date) => {
  const formatedDate = new Date(date);
  return (
    formatedDate.getDate() +
    "/" +
    (formatedDate.getMonth() + 1) +
    "/" +
    formatedDate.getFullYear() +
    " " +
    formatedDate.getHours() +
    ":" +
    formatedDate.getMinutes() +
    ":" +
    formatedDate.getSeconds()
  );
};

export const checkIsOlderThanToday = (date) => {
  const today = new Date();
  const dateToCanpare = new Date(date);
  const timeDiff = today.getTime() - dateToCanpare.getTime();
  const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
  return diffDays < 0;
};

export const sortByAttribute = (list, att = "name", notReverse = true) => {
  if (list && att) {
    list = list.sort((patA, patB) => {
      return patA[att] < patB[att] ? 1 : -1;
    });

    if (notReverse) list = list.reverse();
  }
  return list;
};

export const sortByInnerAttribute = (
  list,
  firstAtt = "",
  secondtAtt = "",
  notReverse = true
) => {
  if (list && firstAtt && secondtAtt) {
    list = list.sort((patA, patB) => {
      return patA[firstAtt][secondtAtt] < patB[firstAtt][secondtAtt] ? 1 : -1;
    });

    if (notReverse) list = list.reverse();
  }
  return list;
};

export const simplestText = (text) => {
  text = "" + text;
  text = text.toLowerCase();
  text = text.replace(new RegExp("[ÁÀÂÃÄ]", "gi"), "a");
  text = text.replace(new RegExp("[ÉÈÊË]", "gi"), "e");
  text = text.replace(new RegExp("[ÍÌÎÏ]", "gi"), "i");
  text = text.replace(new RegExp("[ÓÒÔÕÖ)]", "gi"), "o");
  text = text.replace(new RegExp("[ÚÙÛÜ]", "gi"), "u");
  text = text.replace(new RegExp("[Ç]", "gi"), "c");
  return text;
};

export const simplestNumber = (text) => {
  text = "" + text;
  return text.replace(/[^\d]+/g, "");
};

export const openExteralLink = (link) => {
  const win = window.open(link, "_blank");
  win.focus();
};

export const openAsPopUp = (url, idUser, token) => {
  window.open(url, "mywindow", "menubar=1,resizable=1,width=500,height=500");
};

export const checkIsNewHelper = (date, name) => {
  date = new Date(date).getTime();
  const today = new Date().getTime();
  const diffDays = parseInt((today - date) / (1000 * 60 * 60 * 24), 10);
  return diffDays < 31;
};

export const checkIsEqualText = (textA, textB) => {
  return textA && textB ? simplestText(textA) === simplestText(textB) : false;
};

export const checkIsEqualNumber = (numberA, numberB) => {
  return numberA && numberB ? parseInt(numberA) === parseInt(numberB) : false;
};

export const checkListSize = (list, att) => {
  if (att) return list && list[att] && list[att].length > 0;
  else return list && list.length > 0;
};

export const shortText = (text = "") => {
  let tempBody = text;
  if (text.length > 150) {
    tempBody = text.substr(0, 150);
    if (
      tempBody[tempBody.length - 1] === " " ||
      tempBody[tempBody.length - 1] === "."
    )
      tempBody = text.substr(0, 149);
    tempBody = tempBody + "...";
  }
  return tempBody;
};

export const getColorByLetters = (name = "-") => {
  // let letterA = "";
  // let notFound = [133, 172, 172];
  // let finalColor = "rgba(133, 172, 172)";

  // if (name[0]) letterA = simplestText(name[0]);

  // const dictionary = {
  //   a: [165, 165, 115],
  //   b: [185, 185, 228],
  //   c: [235, 170, 170],
  //   d: [184, 224, 184],
  //   e: [219, 100, 100],
  //   f: [136, 192, 192],
  //   g: [236, 236, 161],
  //   h: [67, 201, 201],
  //   i: [192, 192, 192],
  //   j: [231, 165, 231],
  //   k: [84, 84, 209],
  //   l: [123, 224, 123],
  //   m: [128, 128, 128],
  //   n: [223, 185, 115],
  //   o: [219, 110, 110],
  //   p: [127, 255, 212],
  //   q: [243, 149, 115],
  //   r: [189, 88, 189],
  //   s: [245, 222, 179],
  //   t: [70, 168, 70],
  //   u: [182, 84, 104],
  //   v: [240, 230, 140],
  //   w: [255, 192, 203],
  //   x: [209, 105, 209],
  //   y: [221, 160, 221],
  //   z: [196, 196, 78],
  // };

  // let colorA = dictionary[letterA];
  // if (!colorA) colorA = notFound;

  // const colorMixC = colorA[0];
  // const colorMixM = colorA[1];
  // const colorMixY = colorA[2];
  // finalColor = "rgb(" + colorMixC + ", " + colorMixM + ", " + colorMixY + ")";

  // return finalColor;
  return "#fdfdfd";
};

export const checkValidCPF = (strCpf) => {
  if (!/[0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}/.test(strCpf)) return false;
  strCpf = strCpf = strCpf.replace(/[^\d]+/g, "");

  if (strCpf === "00000000000") return false;

  let soma = 0;

  for (let i = 1; i <= 9; i++)
    soma += parseInt(strCpf.substring(i - 1, i)) * (11 - i);
  let resto = soma % 11;

  if (resto === 10 || resto === 11 || resto < 2) resto = 0;
  else resto = 11 - resto;

  if (resto !== parseInt(strCpf.substring(9, 10))) return false;

  soma = 0;
  for (let i = 1; i <= 10; i++)
    soma += parseInt(strCpf.substring(i - 1, i)) * (12 - i);

  resto = soma % 11;

  if (resto === 10 || resto === 11 || resto < 2) resto = 0;
  else resto = 11 - resto;

  if (resto !== parseInt(strCpf.substring(10, 11))) return false;

  return true;
};

export const checkPasswordValidations = (pw = null) => {
  let validation = {
    hasLowercase: false,
    hasUppercase: false,
    hasNumber: false,
    hasMinEight: false,
  };

  const listLowercase = "qwertyuiopasdfghjklçzxcvbnm";
  const listUppercase = "QWERTYUIOPASDFGHJKLÇZXCVBNM";
  pw = pw.replace(/[ÁÀÂÃÄ]/g, "A");
  pw = pw.replace(/[ÉÈÊË]/g, "E");
  pw = pw.replace(/[ÍÌÎÏ]/g, "I");
  pw = pw.replace(/[ÓÒÔÕÖ]/g, "O");
  pw = pw.replace(/[ÚÙÛÜ]/g, "U");
  pw = pw.replace(/[Ç]/g, "C");
  pw = pw.replace(/[áàâãä]/g, "a");
  pw = pw.replace(/[éèêë]/g, "e");
  pw = pw.replace(/[íìîï]/g, "i");
  pw = pw.replace(/[óòôõö]/g, "o");
  pw = pw.replace(/[úùûü]/g, "u");
  pw = pw.replace(/[ç]/g, "c");

  if (pw) {
    validation.hasNumber = pw.replace(/\D/g, "").length > 0;
    validation.hasMinEight = pw.length >= 8;

    [...pw].forEach((char) => {
      if (!validation.hasLowercase)
        validation.hasLowercase = listLowercase.includes(char);
      if (!validation.hasUppercase)
        validation.hasUppercase = listUppercase.includes(char);
    });
  }
  return validation;
};


export const checkValidCPF_OLD = (strCpf) => {
  let isValid = false;

  isValid = !/[0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}/.test(strCpf);
  isValid = isValid && strCpf !== "000.000.000-00";

  if (isValid) {
    let soma = 0;
    for (let i = 1; i <= 9; i++)
      soma += parseInt(strCpf.substring(i - 1, i)) * (11 - i);
    let resto = soma % 11;
    if (resto === 10 || resto === 11 || resto < 2) resto = 0;
    else resto = 11 - resto;
    isValid = resto === parseInt(strCpf.substring(9, 10));
  }

  if (isValid) {
    let soma = 0;
    for (let i = 1; i <= 10; i++)
      soma += parseInt(strCpf.substring(i - 1, i)) * (12 - i);
    let resto = soma % 11;
    if (resto === 10 || resto === 11 || resto < 2) resto = 0;
    else resto = 11 - resto;
    isValid = resto === parseInt(strCpf.substring(10, 11));
  }

  return true;
};
