import { updateObject } from "../../helpers/index";
import * as actionTypes from "../actions/actionTypes";

const initialState = {
  my_user: null,
  my_user_error: null,
  update_my_user: null,
  update_my_user_error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MY_USER_SET:
      return fetchMyUserSet(state, action);
    case actionTypes.MY_USER_FETCH_FAIL:
      return fetchMyUserFail(state, action);
    case actionTypes.UPDATE_MY_USER_SET:
      return updateMyUserSet(state, action);
    case actionTypes.UPDATE_MY_USER_FETCH_FAIL:
      return updateMyUserFail(state, action);
    default:
      return state;
  }
};

// =========== FETCH MY USER
const fetchMyUserSet = (state, action) => {
  return updateObject(state, {
    my_user: action.data,
  });
};

const fetchMyUserFail = (state, action) => {
  return updateObject(state, {
    my_user_error: action.error,
  });
};

// =========== UPDATE MY USER
const updateMyUserSet = (state, action) => {
  return updateObject(state, {
    update_my_user: action.data,
  });
};

const updateMyUserFail = (state, action) => {
  return updateObject(state, {
    update_my_user_error: action.error,
  });
};

export default reducer;
