import { updateObject } from "../../helpers/index";
import * as actionTypes from "../actions/actionTypes";

const initialState = {
  sales_card: null,
  sales_card_error: null,
  sales_cards: null,
  sales_cards_error: null,
  sales_coupons: null,
  sales_coupons_error: null,
  sales_cities_by_state: null,
  sales_cities_by_state_error: null,
  sales_order: null,
  sales_order_error: null,
  sales_pagseguro: null,
  sales_pagseguro_error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SALES_CARD_SET:
      return fetchSalesCardSuccess(state, action);
    case actionTypes.SALES_CARD_FETCH_FAIL:
      return fetchSalesCardFail(state, action);
    case actionTypes.SALES_CARDS_SET:
      return fetchSalesCardsSuccess(state, action);
    case actionTypes.SALES_CARDS_FETCH_FAIL:
      return fetchSalesCardsFail(state, action);
    case actionTypes.SALES_COUPONS_SET:
      return fetchSalesCouponsSuccess(state, action);
    case actionTypes.SALES_COUPONS_FETCH_FAIL:
      return fetchSalesCouponsFail(state, action);
    case actionTypes.SALES_CITIES_BY_STATE_SET:
      return fetchSalesCitiesByStateSuccess(state, action);
    case actionTypes.SALES_CITIES_BY_STATE_FETCH_FAIL:
      return fetchSalesCitiesByStateFail(state, action);
    case actionTypes.SALES_ORDER_SET:
      return fetchSalesOrderSuccess(state, action);
    case actionTypes.SALES_ORDER_FETCH_FAIL:
      return fetchSalesOrderFail(state, action);
    case actionTypes.SALES_PAGSEGURO_SET:
      return fetchSalesPagseguroSuccess(state, action);
    case actionTypes.SALES_PAGSEGURO_FETCH_FAIL:
      return fetchSalesPagseguroFail(state, action);
    default:
      return state;
  }
};

// =========== MY SALES CARD
const fetchSalesCardSuccess = (state, action) => {
  return updateObject(state, {
    sales_card: action.data,
  });
};

const fetchSalesCardFail = (state, action) => {
  return updateObject(state, {
    sales_card_error: action.error,
  });
};

// =========== MY SALES CARDS
const fetchSalesCardsSuccess = (state, action) => {
  return updateObject(state, {
    sales_cards: action.data,
  });
};

const fetchSalesCardsFail = (state, action) => {
  return updateObject(state, {
    sales_cards_error: action.error,
  });
};

// =========== MY SALES CARDS
const fetchSalesCouponsSuccess = (state, action) => {
  return updateObject(state, {
    sales_coupons: action.data,
  });
};

const fetchSalesCouponsFail = (state, action) => {
  return updateObject(state, {
    sales_coupons_error: action.error,
  });
};

// =========== MY SALES ORDER
const fetchSalesOrderSuccess = (state, action) => {
  return updateObject(state, {
    sales_order: action.data,
  });
};

const fetchSalesOrderFail = (state, action) => {
  return updateObject(state, {
    sales_order_error: action.error,
  });
};

// =========== MY SALES PAGSEGURO
const fetchSalesPagseguroSuccess = (state, action) => {
  return updateObject(state, {
    sales_pagseguro: action.data,
  });
};

const fetchSalesPagseguroFail = (state, action) => {
  return updateObject(state, {
    sales_pagseguro_error: action.error,
  });
};

// =========== MY SALES CITIES BY STATE
const fetchSalesCitiesByStateSuccess = (state, action) => {
  return updateObject(state, {
    sales_cities_by_state: action.data,
  });
};

const fetchSalesCitiesByStateFail = (state, action) => {
  return updateObject(state, {
    sales_cards_error: action.error,
  });
};

export default reducer;
