import { all, takeEvery } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import * as actionsUser from "./ApiUser";
import * as actionsNotifications from "./notifications";
import { initSetSessionActiveSaga } from "./session";
import { initFetchPlansSaga, initFetchPlanChangeSaga } from "./ApiPlans";
import {
  initFetchSalesCardSaga,
  initFetchSalesCardsSaga,
  initFetchSalesCouponsSaga,
  initFetchSalesOrderSaga,
  initFetchSalesPagseguroSaga,
  initFetchSalesCitiesByStateSaga,
} from "./ApiSales";
import { initFetchProductsSaga } from "./ApiProducts";
import { initFetchPatientsPurgeSaga } from "./ApiPatients";

export function* watchSession() {
  yield all([
    takeEvery(actionTypes.SESSION_INIT_ACTIVE, initSetSessionActiveSaga),
  ]);
}

export function* watchPlans(action) {
  yield all([
    takeEvery(actionTypes.PLANS_INIT, initFetchPlansSaga),
    takeEvery(actionTypes.PLAN_CHANGE_INIT, initFetchPlanChangeSaga),
  ]);
}

export function* watchSales(action) {
  yield all([
    takeEvery(actionTypes.SALES_CARD_INIT, initFetchSalesCardSaga),
    takeEvery(actionTypes.SALES_CARDS_INIT, initFetchSalesCardsSaga),
    takeEvery(actionTypes.SALES_COUPONS_INIT, initFetchSalesCouponsSaga),
    takeEvery(actionTypes.SALES_ORDER_INIT, initFetchSalesOrderSaga),
    takeEvery(actionTypes.SALES_PAGSEGURO_INIT, initFetchSalesPagseguroSaga),
    takeEvery(
      actionTypes.SALES_CITIES_BY_STATE_INIT,
      initFetchSalesCitiesByStateSaga
    ),
  ]);
}

export function* watchProducts(action) {
  yield all([takeEvery(actionTypes.PRODUCTS_INIT, initFetchProductsSaga)]);
}

export function* watchPatients(action) {
  yield all([
    takeEvery(actionTypes.PATIENTS_PURGE_INIT, initFetchPatientsPurgeSaga),
  ]);
}

export function* watchUser(action) {
  yield all([
    takeEvery(actionTypes.MY_USER_INIT, actionsUser.initFetchMyUserSaga),
    takeEvery(
      actionTypes.UPDATE_MY_USER_INIT,
      actionsUser.initUpdateMyUserSaga
    ),
  ]);
}

// ========= is old? ==============

export function* watchNotifications(action) {
  yield all([
    takeEvery(
      actionTypes.NOTIFICATIONS_INIT,
      actionsNotifications.initFetchNotificationsSaga
    ),
  ]);
}
