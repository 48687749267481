import React from "react";
import ReactDOM from "react-dom";
//import "./assets/external-css/grid.css";
import "./assets/external-css/bootstrap_nav_tables.css";
import "./assets/external-css/reset.css";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";
import sessionReducer from "./store/reducers/session";
import plansReducer from "./store/reducers/ApiPlans";
import productsReducer from "./store/reducers/ApiProducts";
import salesReducer from "./store/reducers/ApiSales";
import patientsReducer from "./store/reducers/ApiPatients";
import myUserReducer from "./store/reducers/ApiUser";
// import usersReducer from "./store/reducers/users";
// // import notificationsReducer from "./store/reducers/notifications";
import * as sagas from "./store/sagas";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";


// to use DevTools on browser
const composeEnhancers =
    process.env.NODE_ENV === "development" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE_ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE_ :
    null || compose;

// combineReducers
// add all reducers hehe
const root_reducer = combineReducers({
    session: sessionReducer,
    plans: plansReducer,
    products: productsReducer,
    sales: salesReducer,
    patients: patientsReducer,
    myUser: myUserReducer,
    // users: usersReducer,
    // // notifications: notificationsReducer,
});

// SagaMiddleware
const SagaMiddleware = createSagaMiddleware();

const store = createStore(
    root_reducer,
    composeEnhancers(applyMiddleware(SagaMiddleware))
);

SagaMiddleware.run(sagas.watchSession);
SagaMiddleware.run(sagas.watchPlans);
SagaMiddleware.run(sagas.watchProducts);
SagaMiddleware.run(sagas.watchPatients);
SagaMiddleware.run(sagas.watchSales);
SagaMiddleware.run(sagas.watchUser);
// SagaMiddleware.run(sagas.watchNotifications);

const app = ( <
   
    Provider store = { store } >
    <
    I18nextProvider i18n = { i18n } >
    <
    BrowserRouter basename = "/" >
    <
    App / >
    <
    /BrowserRouter> <
    /I18nextProvider> <
    /Provider> 
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();