import * as actionTypes from "./actionTypes";

// ============ PRODUCTS FREE ============
export const initFetchPatientsPurge = () => {
  return {
    type: actionTypes.PATIENTS_PURGE_INIT,
  };
};

export const fetchPatientsPurgeSuccess = (data) => {
  return {
    type: actionTypes.PATIENTS_PURGE_SET,
    data: data,
  };
};

export const fetchPatientsPurgeFail = (error) => {
  return {
    type: actionTypes.PATIENTS_PURGE_FETCH_FAIL,
    error: error,
  };
};
