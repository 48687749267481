/**
 * Action types
 * Think it as routes to usen between:
 * actions, reducers and sagas
 */

// ----------------------------------------------------
// ============== SESSION ==============
export const SESSION_INIT_ACTIVE = "SESSSION_INIT_ACTIVE";
export const SESSION_SET_ACTIVE = "SESSSION_SET_ACTIVE";
export const SESSION_LOGOUT = "SESSION_LOGOUT";
export const SESSION_USER_INIT = "SESSION_USER_INIT";
export const SESSION_USER_SET = "SESSION_USER_SET";

// ============== PLANS ==============
export const PLANS_INIT = "PLANS_INIT";
export const PLANS_SET = "PLANS_SET";
export const PLANS_FETCH_FAIL = "PLANS_FETCH_FAIL";
export const PLAN_CHANGE_INIT = "PLAN_CHANGE_INIT";
export const PLAN_CHANGE_SET = "PLAN_CHANGE_SET";
export const PLAN_CHANGE_FETCH_FAIL = "PLAN_CHANGE_FETCH_FAIL";

// ============== SALES ==============
export const SALES_CARD_INIT = "SALES_CARD_INIT";
export const SALES_CARD_SET = "SALES_CARD_SET";
export const SALES_CARD_FETCH_FAIL = "SALES_CARD_FETCH_FAIL";
export const SALES_CARDS_INIT = "SALES_CARDS_INIT";
export const SALES_CARDS_SET = "SALES_CARDS_SET";
export const SALES_CARDS_FETCH_FAIL = "SALES_CARDS_FETCH_FAIL";
export const SALES_COUPONS_INIT = "SALES_COUPONS_INIT";
export const SALES_COUPONS_SET = "SALES_COUPONS_SET";
export const SALES_COUPONS_FETCH_FAIL = "SALES_COUPONS_FETCH_FAIL";
export const SALES_ORDER_INIT = "SALES_ORDER_INIT";
export const SALES_ORDER_SET = "SALES_ORDER_SET";
export const SALES_ORDER_FETCH_FAIL = "SALES_ORDER_FETCH_FAIL";
export const SALES_PAGSEGURO_INIT = "SALES_PAGSEGURO_INIT";
export const SALES_PAGSEGURO_SET = "SALES_PAGSEGURO_SET";
export const SALES_PAGSEGURO_FETCH_FAIL = "SALES_PAGSEGURO_FETCH_FAIL";
export const SALES_CITIES_BY_STATE_INIT = "SALES_CITIES_BY_STATE_INIT";
export const SALES_CITIES_BY_STATE_SET = "SALES_CITIES_BY_STATE_SET";
export const SALES_CITIES_BY_STATE_FETCH_FAIL =
  "SALES_CITIES_BY_STATE_FETCH_FAIL";

// ============== PRODUCTS ==============
export const PRODUCTS_INIT = "PRODUCTS_INIT";
export const PRODUCTS_SET = "PRODUCTS_SET";
export const PRODUCTS_FETCH_FAIL = "PRODUCTS_FETCH_FAIL";

export const PRODUCTS_PAID_INIT = "PRODUCTS_PAID_INIT";
export const PRODUCTS_PAID_SET = "PRODUCTS_PAID_SET";
export const PRODUCTS_PAID_FETCH_FAIL = "PRODUCTS_PAID_FETCH_FAIL";

// ============== PATIENTES ==============
export const PATIENTS_PURGE_INIT = "PATIENTS_PURGE_INIT";
export const PATIENTS_PURGE_SET = "PATIENTS_PURGE_SET";
export const PATIENTS_PURGE_FETCH_FAIL = "PATIENTS_PURGE_FETCH_FAIL";

// ============== MY_USER ==============
export const MY_USER_INIT = "MY_USER_INIT";
export const MY_USER_SET = "MY_USER_SET";
export const MY_USER_FETCH_FAIL = "MY_USER_FETCH_FAIL";

export const UPDATE_MY_USER_INIT = "UPDATE_MY_USER_INIT";
export const UPDATE_MY_USER_SET = "UPDATE_MY_USER_SET";
export const UPDATE_MY_USER_FETCH_FAIL = "UPDATE_MY_USER_FETCH_FAIL";

// ============== USER ==============
export const USER_INIT = "USER_INIT";
export const USER_SET = "USER_SET";
export const USER_FETCH_FAIL = "USER_FETCH_FAIL";

export const NOTIFICATIONS_INIT = "NOTIFICATIONS_INIT";
export const NOTIFICATIONS_SET = "NOTIFICATIONS_SET";
export const NOTIFICATIONS_FETCH_FAIL = "NOTIFICATIONS_FETCH_FAIL";
