import { useLocation } from "react-router-dom";

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const getPathArray = (path = "") => {
  const test = Array.from(path.split("/"));
  if (test.length > 0 && test[0] === "") test.shift();

  return test;
};

export const getSimplestPathname = (path = "") => {
  return path.replace(/\/|/g, "");
};

export const get1ndPath = (path = "") => {
  const list_path = path.split("/");
  // -> /jogos/jogo/:num
  // -> ["", "jogos", "jogo", :num]
  return list_path[1] ? list_path[1] : null;
};
export const get2ndPath = (path = "") => {
  const list_path = path.split("/");
  // -> /jogos/jogo/:num
  // -> ["", "jogos", "jogo", :num]
  return list_path[2] ? list_path[2] : null;
};

export const get3rdPath = (path = "") => {
  const list_path = path.split("/");
  // -> /jogos/jogo/:num
  // -> ["", "jogos", "jogo", :num]
  return list_path[3] ? list_path[3] : null;
};
/*
 * {
 * key:value
 * key:"" //if = nothing
 * }
 *
 */
export const getQueryStringParams = (query) => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split("&")
        .reduce((params, param) => {
          let [key, value] = param.split("=");
          params[key] = value
            ? decodeURIComponent(value.replace(/\+/g, " "))
            : "";
          return params;
        }, {})
    : {};
};
