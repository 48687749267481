export const ACCOUT_PERMISSIONS_API = "permissions"; // Fron Token Login

export const CUSTOM_PERMISSIONS = "custom:permissions";
export const ACCOUT_PERMISSIONS = "permissions";
// used on login URLs
export const USERTYPE_PRO_PAID = "user_pro_paid";
export const USERTYPE_PRO_FREE = "user_pro_free";
export const USERTYPE_SINGLE_PAID = "user_single_paid";
export const USERTYPE_SINGLE_FREE = "user_single_free";
export const USERTYPE_FAMILY_PAID = "user_family_paid";
export const USERTYPE_FAMILY_FREE = "user_family_free";

export const USERTYPE_PRO_PAID_PERMISSIONS = "100000";
export const USERTYPE_PRO_FREE_PERMISSIONS = "010000";
export const USERTYPE_SINGLE_PAID_PERMISSIONS = "001000";
export const USERTYPE_SINGLE_FREE_PERMISSIONS = "000100";
export const USERTYPE_FAMILY_PAID_PERMISSIONS = "000010";
export const USERTYPE_FAMILY_FREE_PERMISSIONS = "000001";

export const USERTYPES = {
  0: USERTYPE_PRO_PAID,
  1: USERTYPE_PRO_FREE,
  2: USERTYPE_SINGLE_PAID,
  3: USERTYPE_SINGLE_FREE,
  4: USERTYPE_FAMILY_PAID,
  5: USERTYPE_FAMILY_FREE,
};
export const SIGNUP = "SIGNUP";
export const VERIFY_CODIGO = "verify_codigo";
export const CUSTOM_START_ACC_TYPE = "custom:start_acc_type";
export const CUSTOM_BIRTH_DATE = "custom:birth_date";
export const CUSTOM_INVITE_CODE = "custom:invitecode";
export const CUSTOM_PARENT_SUB = "custom:parent_sub";
export const CUSTOM_OCCUPATION = "custom:occupation";
export const CUSTOM_START_PLATAFORMA = "custom:platform";
export const APP_KEY = "afinando_v3";
export const CUSTOM_START_ACC_TYPE_PRO = APP_KEY + ":pro";
export const CUSTOM_START_ACC_TYPE_SIN = APP_KEY + ":sin";
export const CUSTOM_START_ACC_TYPE_FAM = APP_KEY + ":fam";

// Login
export const NOT_AUTHORIZED_EXCEPTION = "NotAuthorizedException"; //"Incorrect username or password."
// Verification Code
export const CODE_MISMATCH_EXCEPTION = "CodeMismatchException"; //"Invalid verification code provided, please try again."
export const USER_NOT_CONFIRMED_EXCEPTION = "UserNotConfirmedException"; //"User is not confirmed."
// Signup
export const INVALID_PASSWORD_EXCEPTION = "InvalidPasswordException"; //"Password did not conform with policy: Password not long enough or does not cointain uppercase characters."
export const INVALID_PARAMETER_EXCEPTION = "InvalidParameterException"; //""password  must have length greater than or equal to 6."
export const USERNAME_EXISTS_EXCEPTION = "UsernameExistsException"; //"An account with the given email already exists."
export const LIMIT_EXCEEDED_EXCEPTION = "LimitExceededException"; //"Attempt limit exceeded, please try after some time.""
