import React from "react";
import classes from "./Aside.module.scss";
import { NavLink, Route, Link } from "react-router-dom";
// import { faCoffee } from "@fortawesome/fontawesome-pro";
import afinandoPlus from "../../assets/images/logos/afinando_plus_tint_purple.svg";
import afinandoCerebro from "../../assets/images/logos/afinando_tint_purple.svg";
import audbility from "../../assets/images/logos/audbility_tint_purple.svg";
import audiofoco from "../../assets/images/logos/audiofoco_tint_purple.svg";
import { ReactComponent as Triangulo } from "../../assets/images/right_nav_arrow.svg";
import { ReactComponent as Triangulo2 } from "../../assets/images/left_nav_arrow.svg";

import {
  faShoppingCart,
  faCog,
  faUser,
  faQuestion,
  faCircleExclamation
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { R_SOLUCOES, R_ALL_PAYMENTS_PLANS, R_FAQ } from "../../constants/routes";


const Aside = ({ className }) => {
  const { t } = useTranslation();
  return (
    <>
      <aside className={`${className} + ${classes.Aside}`}>
        {/* <div
          className={classes.TitleAside}
        >{`Boas vindas`}</div> */}
        <nav className={classes.Nav}>
          {/* <NavLink
            activeClassName={classes.Active}
            className={classes.Link}
            to={R_SOLUCOES}
          >
            <FontAwesomeIcon className={classes.Icon} icon={faShoppingCart} />
            <span className={classes.SetaEsquerda}>
              <Triangulo className={classes.trianguloEsquerda} />
              <Triangulo2 className={classes.trianguloDireita} />
              {t("navs.solutions")}
            </span>
          </NavLink> */}

          <NavLink
            activeClassName={classes.Active}
            className={classes.Link}
            to={R_ALL_PAYMENTS_PLANS}
          >
            <FontAwesomeIcon className={classes.Icon} icon={faUser} />
            <span className={classes.SetaEsquerda}>
              <Triangulo className={classes.trianguloEsquerda} />
              <Triangulo2 className={classes.trianguloDireita} />
              {t("navs.payments_and_plans")}
            </span>
          </NavLink>

          <NavLink
            activeClassName={classes.Active}
            className={classes.Link}
            to={"/configuracoes-de-conta"}
          >
            <FontAwesomeIcon className={classes.Icon} icon={faCog} />
            <span className={classes.SetaEsquerda}>
              <Triangulo className={classes.trianguloEsquerda} />
              <Triangulo2 className={classes.trianguloDireita} />
              {t("navs.account_settings")}
            </span>
          </NavLink>

          <NavLink
            activeClassName={classes.Active}
            className={classes.Link}
            to={R_FAQ}
          >
            <FontAwesomeIcon
              className={classes.Icon}
              icon={faCircleExclamation}
            />
            <span className={classes.SetaEsquerda}>
              <Triangulo className={classes.trianguloEsquerda} />
              <Triangulo2 className={classes.trianguloDireita} />
              {/* {t("global.terms_of_use")} */}

              {t("aside.help")}
            </span>
          </NavLink>

          {/*  ACESSOS RÁPIDOS
           <div
            className={classes.TitleAside}
          >{t("aside.access")}</div>

          <div className={classes.botoes}>

            <a href="https://novo.afinandoocerebro.com.br/" target="_blank" className={classes.buttonA}>
              <img src={afinandoCerebro} alt="imagem afinandinho" />
            </a>

            <a href="https://afinandoplus.probrain.com.br/" target="_blank" className={classes.buttonA}>
              <img src={afinandoPlus} alt="imagem afinandinho" />
            </a>

            <a href="https://afinandoocerebro.com.br/painel/login" target="_blank" className={classes.buttonA}>
              <img src={audbility} alt="imagem afinandinho" />
            </a>

            <a href="mailto:atendimento@probrain.com.br" target="_blank" className={classes.buttonA}>
              <img src={audiofoco} alt="imagem afinandinho" />
            </a>
          </div> */}
        </nav>
      </aside>
    </>
  );
};

export default Aside;
