import React, { useState } from "react";
import classes from "./TopNav.module.scss";
import { ALT_LOGO } from "../../../constants/global";
import { Auth } from "aws-amplify";
import { NavLink, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import * as actions from "../../../store/actions";
// import * as btns from "../../../constants/buttons";
import * as helpers from "../../../helpers/index";
import * as routes from "../../../constants/routes";
import Avatar from "../../Avatar/Avatar";
import LogoBeta from "../../../assets/images/logo_horizontal.png";
import {
  STATIC_API_AFINANDO,
  STATIC_API_AFINANDOPLUS,
  STATIC_FILE_PRIV_POLITICS,
  STATIC_FILE_USE_TERMS,
  STATIC_FILE_SECURITY_POLICY,
} from "../../../constants/api";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Flags ocultadsa
// import NavLinkItem from "./NavLinkItem/NavLinkItem";
// import RoundedButton from "../../Buttons/RoundedButton/RoundedButton";

const TopNav = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  // const location = useLocation();
  const [isUserNavOpen, toggleUserNav] = useState(false);

  const dispatch = useDispatch();

  const resetNavs = () => {
    toggleUserNav(false);
  };
  const getUserRNavBlock = (name = "") => {
    const preventReOpen = isUserNavOpen ? classes.PreventReOpen : "";
    let click = () => toggleUserNav(!isUserNavOpen);
    return (
      <>
        <div className={classes.UserMenu + " " + preventReOpen}>
          <button tabIndex={0} className={classes.BtnAvatar} onClick={click}>
            <Avatar className={classes.TopNavAvatar} name={name} />
            <p className={classes.Name}>
              <span>{t("global.hi")},</span>
              <span className={classes.UserName}>{name}</span>
            </p>
            <FontAwesomeIcon className={classes.IconNav} icon={faTimes} />
          </button>
        </div>
      </>
    );
  };

  const getUserAvatarSection = (name) => {
    const preventReOpen = isUserNavOpen ? classes.PreventReOpen : "";
    let click = () => {
      toggleUserNav(!isUserNavOpen);
    };
    return (
      <>
        {/* {_flags} */}
        <div className={classes.UserMenu + " " + preventReOpen}>
          <button tabIndex={0} className={classes.BtnAvatar} onClick={click}>
            <Avatar className={classes.TopNavAvatar} name={name} />
            <p className={classes.Name}>
              <span>{t("global.hi")},</span>
              <span className={classes.UserName}>{name}</span>
            </p>
            <FontAwesomeIcon className={classes.IconNav} icon={faBars} />
          </button>
        </div>
      </>
    );
  };

  const getBtnToggleMenu = (isUserSingle) => {
    let click = () => toggleUserNav(!isUserNavOpen);
    const singleUserClass = isUserSingle ? classes.SingleUser : "";
    const btnOpen = isUserNavOpen ? classes.BtnArrowDown : "";
    const preventReOpenClass = isUserNavOpen ? classes.PreventReOpen : "";
    return (
      <div className={preventReOpenClass + " " + singleUserClass}>
        <button
          tabIndex={0}
          className={classes.BtnToggleMenu + " " + btnOpen}
          onClick={click}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
    );
  };

  // const _flags = getFlags();
  let userName = "";
  let userAvatarSection = null;
  let _btnToggleMenu = null;
  let _userRNavBlock = null;
  const isUserSingle = helpers.isUserSingle();

  if (helpers.isAuthenticated()) {
    if (Auth?.user?.attributes?.given_name)
      userName = Auth.user.attributes.given_name;
    else if (Auth?.user?.signInUserSession?.idToken?.payload?.given_name)
      userName = Auth.user.signInUserSession.idToken.payload.given_name;

    userAvatarSection = getUserAvatarSection(userName);
    _btnToggleMenu = getBtnToggleMenu(isUserSingle);
    _userRNavBlock = getUserRNavBlock(userName);
  }

  let _mainUserNav = (
    <nav className={classes.RNavMain}>
      <NavLink
        onClick={() => resetNavs()}
        to={routes.R_ALL_PAYMENTS_PLANS}
        exact={true}
        className={classes.SubLink}
      >
        {t("navs.payments_and_plans")}
      </NavLink>

      <NavLink
        onClick={() => resetNavs()}
        to={routes.R_ACCOUNT_SETTINGS}
        exact={true}
        className={classes.SubLink}
      >
        {t("navs.account_settings")}
      </NavLink>

      <NavLink
        onClick={() => resetNavs()}
        to={routes.R_FAQ}
        exact={true}
        className={classes.SubLink}
      >
        {/* PRECISA DE AJUDA? */}
        {t("navs.help_nav")}
      </NavLink>
    </nav>
  );
  return (
    <>
      <header className={classes.TopNav}>
        <section className={classes.DesktopNav}>
          <section className={classes.LeftMenu}>
            <div className={classes.Logo}>
              <NavLink
                onClick={() => resetNavs()}
                to={routes.R_ALL_PAYMENTS_PLANS}
                exact={true}
              >
                <figure>
                  <img src={LogoBeta} alt={ALT_LOGO} />
                </figure>
              </NavLink>
            </div>
          </section>

          <div className={classes.RightMenu}>{userAvatarSection}</div>
          {_btnToggleMenu}
        </section>
        {isUserNavOpen && (
          <div
            className={isUserNavOpen ? classes.Overlay : ""}
            onClick={() => resetNavs()}
          ></div>
        )}
        <div
          className={
            isUserNavOpen
              ? classes.NavRight + " " + classes.OpenRNav
              : classes.NavRight
          }
        >
          {isUserNavOpen && (
            <>
              <div className={classes.RNavUserHead}>{_userRNavBlock}</div>
              {_mainUserNav}

              <nav className={classes.RNavUserNav}>
                <hr />
                <a
                  tabIndex="0"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => resetNavs()}
                  href={STATIC_FILE_USE_TERMS}
                  className={classes.SubLink}
                >
                  {t("global.terms_of_use")}
                </a>
                <a
                  tabIndex="0"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => resetNavs()}
                  href={STATIC_FILE_SECURITY_POLICY}
                  className={classes.SubLink}
                >
                  {t("global.security_policy")}
                </a>
                <a
                  tabIndex="0"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => resetNavs()}
                  href={STATIC_FILE_PRIV_POLITICS}
                  className={classes.SubLink}
                >
                  {t("global.terms_of_privacy")}
                </a>

                <div className={classes.SubLink}>
                  <hr />
                  <button
                    tabIndex="0"
                    onClick={() => {
                      resetNavs();
                      Auth.signOut().then((res) => {
                        history.push("/login");
                        dispatch(actions.initLogout());
                      });
                    }}
                    className={classes.SignOut + " " + classes.SubLink}
                  >
                    {t("global.signout")}
                  </button>
                </div>
              </nav>
            </>
          )}
        </div>
      </header>
    </>
  );
};

export default TopNav;
