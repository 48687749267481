import React, { useEffect, useState } from "react";
import classes from "./Layout.module.scss";
import Aside from "../../components/Aside";
import TopNav from "../../components/Navs/TopNav";
import { useTranslation } from "react-i18next";
import Footer from "../../components/Footer";
import Spinner from "../../components/UI/Spinner";

//import { useHistory } from "react-router-dom";

import Main from "../../components/Main";
import { useSelector } from "react-redux";
//
import { Redirect, useLocation } from "react-router";
import { R_LOGIN, R_SOLUCOES, R_SHOPPING_CART } from "../../constants/routes";
import {
  getQueryStringParams,
  get1ndPath,
  checkIsEqualText,
} from "../../helpers";
import { GET_PROD_OR_DEV } from "../../constants/api";
import { DEV } from "../../constants/defaults";
import { ConsoleLogger } from "@aws-amplify/core";
const Layout = ({ children, structure }) => {
  const { t } = useTranslation();
  const { session } = useSelector((state) => state.session);
  const [_content, setContent] = useState(<></>);
  const [_main, setMain] = useState(<></>);

  let location = useLocation();
  //let history = useHistory();

  let _footer = <Footer />;

  //Wrap Padding for Solutions only & Cart
  let padd_solutions = null;
  let grid_12 = "col-12";

  //Tratado o caminho para identificar a página
  let caminhoURL = location.pathname;
  caminhoURL = caminhoURL.split("/");
  caminhoURL = caminhoURL[1];
  if ("produtos" === caminhoURL || "carrinho-de-compras" === caminhoURL) {
    padd_solutions = classes.padd_solutions;
    grid_12 = "";
  }

  useEffect(() => {
    if (structure == 2) {
      setMain(
        <>
          <Main className={classes.MainOffset + " container-fluid " + padd_solutions}>
            <div>
              <div className={grid_12}>{children}</div>
            </div>
          </Main>
        </>
      );
    } else {
      setMain(
        <>
          <Main className={classes.Main + " container-fluid " + padd_solutions}>
            <div>
              <div className={grid_12}>{children}</div>
            </div>
          </Main>
        </>
      );
    }
  }, [children, structure]);

  let _aside = <div />;
  if (session) _aside = <Aside className={classes.Aside} />;

  useEffect(() => {
    makeStructure(structure);
  }, [structure, _main]);

  function makeStructure(param) {
    switch (param) {
      case "1": {
        setContent(
          <div>
            <TopNav />
            <div className={classes.Layout}>
              {_main}
              <div className="modal">
                <div className="content"></div>
              </div>
            </div>
          </div>
        );
        break;
      }
      case "2": {
        setContent(
          <div>
            <div>
              {_main}
              <div className="modal">
                <div className="content"></div>
              </div>
            </div>
          </div>
        );
        break;
      }
      default: {
        setContent(
          <div>
            <TopNav />
            <div className={classes.Layout}>
              {_aside}
              {_main}
              <div className="modal">
                <div className="content"></div>
              </div>
            </div>
            {_footer}
          </div>
        );
        break;
      }
    }
  }

  //const location = useLocation();
  const { addlogin } = getQueryStringParams(location.search);

  // console.log("------------------");
  // console.log("get1ndPath()", get1ndPath(location.pathname));
  // console.log(
  //   "get1ndPath(location.pathname) === R_LOGIN",
  //   get1ndPath(location.pathname) === R_LOGIN
  // );
  // console.log(
  //   "get1ndPath(location.pathname) === R_SOLUCOES",
  //   get1ndPath(location.pathname) === R_SOLUCOES
  // );
  // console.log("------------------");
  // if (
  //   GET_PROD_OR_DEV === DEV &&
  //   !addlogin &&
  //   !(
  //     checkIsEqualText(get1ndPath(location.pathname), R_LOGIN) ||
  //     checkIsEqualText(get1ndPath(location.pathname), R_SOLUCOES)
  //   )
  // ) {
  //   console.log("if (GET_PROD_OR_DEV === DEV && !addlogin) {");
  //   _content = <Redirect to={R_SOLUCOES} />;
  // }

  return _content;
};
export default Layout;
