import * as actionTypes from "./actionTypes";

// ============ MY USER ============

export const initfetchMyUserById = (id) => {
	return {
		type: actionTypes.MY_USER_INIT,
		id: id,
	};
};

export const fetchMyUserByIdSuccess = (user) => {
	return {
		type: actionTypes.MY_USER_SET,
		user: user,
	};
};

export const fetchMyUserByIdFail = () => {
	return {
		type: actionTypes.MY_USER_FETCH_FAIL,
	};
};

// ============ Parent USER ============

export const initfetchUserById = (id) => {
	return {
		type: actionTypes.USER_INIT,
		id: id,
	};
};

export const fetchUserByIdSuccess = (user) => {
	return {
		type: actionTypes.USER_SET,
		user: user,
	};
};

export const fetchUserByIdFail = () => {
	return {
		type: actionTypes.USER_FETCH_FAIL,
	};
};
