import * as aTypes from "./actionTypes";

// ============ LOGIN ============
export const initSessionActive = (session) => {
  return {
    type: aTypes.SESSION_INIT_ACTIVE,
    session: session,
  };
};

export const setSessionActive = (session) => {
  return {
    type: aTypes.SESSION_SET_ACTIVE,
    session: session,
  };
};

// ============ LOGOUT ============
export const initLogout = () => {
  return {
    type: aTypes.SESSION_LOGOUT,
  };
};

// ============ MY USER ============
export const initFetchSessionMyUser = (user_id) => {
  return {
    type: aTypes.SESSION_USER_INIT,
    user_id: user_id,
  };
};
