import { put } from "redux-saga/effects";
import axios from "../../axios/axios";
import * as actions from "../actions";
import * as kApi from "../../constants/api";
import { refreshTokenSaga } from "./LocalRefreshToken";
import { Auth } from "aws-amplify";

export function* initFetchMyUserSaga(action) {
  try {
    const response = yield axios.get(kApi.API_URL_USER, {
      headers: {
        Authorization: Auth.user.signInUserSession.idToken.jwtToken,
      },
    });
    yield put(actions.fetchMyUserSet(response.data));
  } catch (error) {
    try {
      const refreshToken = yield refreshTokenSaga();
      const response = yield axios.get(kApi.API_URL_USER, {
        headers: {
          Authorization: refreshToken.signInUserSession.idToken.jwtToken,
        },
      });
      yield put(actions.fetchMyUserSet(response.data));
    } catch (error) {
      yield put(actions.fetchMyUserFail(error));
    }
  }
}

export function* initUpdateMyUserSaga(action) {
  let _requestBody = null;
  try {
    _requestBody = JSON.stringify(...action.data);
  } catch (error) {
    _requestBody = {
      ...action.data,
    };
  }

  try {
    const response = yield axios.put(kApi.API_URL_USER, _requestBody, {
      headers: {
        Authorization: Auth.user.signInUserSession.idToken.jwtToken,
      },
    });
    yield put(actions.updateMyUserSet(response.data));
  } catch (error) {
    try {
      const refreshToken = yield refreshTokenSaga();
      const response = yield axios.put(kApi.API_URL_USER, _requestBody, {
        headers: {
          Authorization: refreshToken.signInUserSession.idToken.jwtToken,
        },
      });
      yield put(actions.updateMyUserSet(response.data));
    } catch (error) {
      yield put(actions.updateMyUserFail(error));
    }
  }
}
