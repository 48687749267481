import { put } from "redux-saga/effects";
import axios from "../../axios/axios";
import * as actions from "../actions";
import * as kApi from "../../constants/api";
import { refreshTokenSaga } from "./LocalRefreshToken";
import { Auth } from "aws-amplify";

export function* initFetchPlansSaga(action) {
  try {
    const response = yield axios.get(kApi.API_URL_PLANS, {
      headers: {
        Authorization: Auth.user.signInUserSession.idToken.jwtToken,
      },
    });
    yield put(actions.fetchPlansSuccess(response.data));
  } catch (error) {
    try {
      const refreshToken = yield refreshTokenSaga();
      const response = yield axios.get(kApi.API_URL_PLANS, {
        headers: {
          Authorization: refreshToken.signInUserSession.idToken.jwtToken,
        },
      });
      yield put(actions.fetchPlansSuccess(response.data));
    } catch (error) {
      yield put(actions.fetchPlansFail(error));
    }
  }
}

export function* initFetchPlanChangeSaga(action) {
  try {
    const response = yield axios.post(
      kApi.API_URL_PLAN_CHANGE + "/" + action.product_id,
      {},
      {
        headers: {
          Authorization: Auth.user.signInUserSession.idToken.jwtToken,
        },
      }
    );
    yield put(actions.fetchPlanChangeSuccess(response.data));
  } catch (error) {
    try {
      const refreshToken = yield refreshTokenSaga();
      const response = yield axios.post(
        kApi.API_URL_PLAN_CHANGE + "/" + action.product_id,
        {},
        {
          headers: {
            Authorization: refreshToken.signInUserSession.idToken.jwtToken,
          },
        }
      );
      yield put(actions.fetchPlanChangeSuccess(response.data));
    } catch (error) {
      yield put(actions.fetchPlanChangeFail(error));
    }
  }
}
