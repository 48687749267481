import React from "react";
import classes from "./Avatar.module.scss";
import { getColorByLetters } from "../../helpers/utilityHelpers";

const Avatar = ({ img, className = "", noMargin, mRight, name = "" }) => {
  const _propsClass = className ? " " + className : " ";
  const _marginClass = noMargin
    ? " " + classes.NoMargin
    : mRight
    ? " " + classes.MoreMargin
    : "";
  let _noImgClasse = " " + classes.NoImgClasse;

  const finalName = name[0] ? name[0].toUpperCase() : "-";

  let _imgBG = {
    background: getColorByLetters(name),
    filter: "brightness(1.3)",
  };
  if (img) {
    _imgBG = { backgroundImage: "url(" + img + ")" };
    _noImgClasse = " ";
  }
  return (
    <div className={classes.Avatar + _marginClass + _propsClass + _noImgClasse}>
      <div className={classes.Img} style={_imgBG}></div>
      <span className={classes.Letters}>{finalName}</span>
    </div>
  );
};

export default Avatar;
