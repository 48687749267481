//  !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//  !!! IT MUST BE IN LOWER CASE !!!
//  !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
export const PROD = "prod";
export const DEV = "dev";
export const WEB = "web";

// Default portal language
export const DEFAULT_LANGUAGE = "pt";
export const VIDEO = "video";
export const GAME = "game";
export const TEXT = "text";

export const REQUIRED_FIELD = "Campo obrigatório";
// Default images
export const DEFAULT_AVATAR_IMG = "";

export const LIFETIME = "lifetime";
export const MAP_PERIODICITY = {
  lifetime: "global.lifetime",
  annual: "global.annual",
  monthly: "global.monthly",
};

export const TYPE_PAYMENT_PAGSEGURO = "pagseguro";
export const TYPE_PAYMENT_CREDIT_CARD = "creditcard";
export const TYPE_PAYMENT_BOLETO = "boleto";
export const TYPE_PAYMENT_PIX = "pix";

export const MAP_PAYMENTS_METHODS = {
  [TYPE_PAYMENT_PAGSEGURO]: "PagSeguro",
  [TYPE_PAYMENT_CREDIT_CARD]: "global.creditcard",
  [TYPE_PAYMENT_BOLETO]: "Boleto",
  [TYPE_PAYMENT_PIX]: "Pix",
};

export const EBANX_STATUS_CONFIRMED = "CO";
export const EBANX_STATUS_CANCELED = "CA";
export const EBANX_STATUS_WAINTING = "PE";
export const MAP_EBANX_STATUS_MSG = {
  [EBANX_STATUS_CONFIRMED]: "global.ebanx_status_confirmed",
  [EBANX_STATUS_CANCELED]: "global.ebanx_status_canceled",
  [EBANX_STATUS_WAINTING]: "global.ebanx_status_wainting",
};
export const ALERT_TYPE_SUCCESS = "success";
export const ALERT_TYPE_WARNING = "warning";
export const ALERT_TYPE_DANGER = "danger";
export const ALERT_TYPE_WAIT = "wait";

// export const MAP_CARDS = {
//   amex: "x",
//   aura: "x",
//   diners: "x",
//   discover: "x",
//   elo: "x",
//   visa: "x",
//   mastercard: "x",
//   hipercard: "x",
// };
