import { updateObject, getUserAccountType } from "../../helpers/index";
import * as aTypes from "../actions/actionTypes";
import { Auth } from "aws-amplify";

// const initialState = null;
const initialState = {
  activeUser: null,
  // activeUser: {
  //   avatar: null,
  //   birth_date: null,
  //   given_name: "Conta Single_free",
  //   sub: "00ef426e-e660-414e-ae91-e2674b54e2c7",
  // },
  session: null,
  accessToken: null,
  idToken: null,
  refreshToken: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case aTypes.SESSION_LOGOUT:
      return initLogout(state, action);
    case aTypes.SESSION_SET_ACTIVE:
      return initSessionActive(state, action);
    default:
      return state;
  }
};

// =========== LOGOUT ===========
const initLogout = (state, action) => {
  Auth.signOut();
  return updateObject(state, initialState);
};

// =========== SESSION ===========
const initSessionActive = (state, action) => {
  if (!!!action.session.attributes) {
    action.session.attributes = action.session.signInUserSession.idToken.payload;
  }
  const session = {
    account_type: getUserAccountType(
      action.session.attributes["custom:permissions"]
    ),
    avatar: action.session.avatar,
    permissions: action.session.attributes["custom:permissions"],
    email: action.session.attributes.email,
    email_verified: action.session.attributes.email_verified,
    given_name: action.session.attributes.given_name,
    sub: action.session.attributes.sub,
  };
  const accessToken = action.session.signInUserSession.accessToken.jwtToken;
  const idToken = action.session.signInUserSession.idToken.jwtToken;
  const refreshToken = action.session.signInUserSession.refreshToken.token;
  return updateObject(state, {
    session: session,
    accessToken: accessToken,
    idToken: idToken,
    refreshToken: refreshToken,
  });
};

export default reducer;
