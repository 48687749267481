/**
 * Última atualização
 */
import * as kApi from "../constants/users_login";
import { Auth } from "aws-amplify";
import { GET_PROD_OR_DEV } from "../constants/api";
import { DEV } from "../constants/defaults";

// -------------------------------------------------------
// ------ ACOOUNT TYPE FROM URL /login/account_type ------
// -------------------------------------------------------

const getPermissionByAppKey = (permission = null) => {
  if (permission) {
    try {
      permission = JSON.parse(permission);
    } catch (e) {}
    if (permission[kApi.APP_KEY]) permission = permission[kApi.APP_KEY];
  } else {
    //  console.warn("getPermissionByAppKey", "Permission undefined");
  }
  return permission;
};

// Only to login page
export const getPermitionByURL = (userPermission) => {
  switch (userPermission) {
    case kApi.USERTYPE_PRO_FREE:
      return kApi.CUSTOM_START_ACC_TYPE_PRO; //"afinando_v3:pro"
    case kApi.USERTYPE_SINGLE_FREE:
      return kApi.CUSTOM_START_ACC_TYPE_SIN; //"afinando_v3:sin"
    case kApi.USERTYPE_FAMILY_FREE:
      return kApi.CUSTOM_START_ACC_TYPE_FAM; //"afinando_v3:fam"
    default:
      return kApi.CUSTOM_START_ACC_TYPE_SIN; //"afinando_v3:sin"
  }
};
// -------------------------------------------------------

export const getUserAccountType = (userPermission = "") => {
  let notFound = true;
  let position = 0;
  userPermission = getPermissionByAppKey(userPermission);
  while (notFound && position < userPermission.length) {
    if (parseInt(userPermission[position]) === 1) notFound = false;
    else position++;
  }
  return kApi.USERTYPES[position]
    ? kApi.USERTYPES[position]
    : kApi.USERTYPE_SINGLE_FREE;
};

// -----------------------------------------------
// -----------------------------------------------
// Possible to use with Auth ID or Patient ID
export const hasPermission = (userPermission = "", activityPermission = "") => {
  userPermission = getPermissionByAppKey(userPermission);
  activityPermission = getPermissionByAppKey(activityPermission);
  let hasPermission = false;
  if (activityPermission && userPermission) {
    let notFound = true;
    let position = 0;

    while (notFound && position < userPermission.length) {
      if (parseInt(userPermission[position]) === 1) notFound = false;
      else position++;
    }

    if (
      activityPermission &&
      activityPermission[position] &&
      parseInt(activityPermission[position]) === 1
    )
      hasPermission = true;
  }
  return hasPermission;
};

// -----------------------------------------------
// -----------------------------------------------
/**
 * @description
 * Check exist Auth.user.attributes
 * Auth && Auth.user && Auth.user.attributes ? true : false;
 */
export const isAuthenticated = (session = null) => {
  if (GET_PROD_OR_DEV === DEV && Auth && Auth.user && Auth.user.attributes) {
    // console.log("Auth sub", Auth.user);
    // console.log("Auth sub", Auth.user.attributes);
    // console.log("Auth jwtToken", Auth.user.signInUserSession.idToken.jwtToken);
  }
  let user = Auth.user;
  let is_authenticated = session || Auth && Auth.user && (user.attributes || (user.signInUserSession && user.signInUserSession.idToken.payload)) ? true : false;
  return is_authenticated
};

export const getLinkMain = () => {
  let mail = "mailto:atendimento@probrain.com.br";
  mail += "?subject=Cancelamento de plano";
  mail += "&body=Olá,%0D%0DQuero cancelar meu plano do Afinando o Cérebro.";
  let user = Auth?.user;
  let email = ""
  if (user && user.attributes && Auth.user.attributes.email) email =Auth.user.attributes.email 
  else if(user && user.signInUserSession && user.signInUserSession.idToken.payload && user.signInUserSession.idToken.payload.email)email = user.signInUserSession.idToken.payload.email
  if (email != "") {
    mail += "%0D%0DE-mail cadastrado: '" + email + "'.";
  }
  return mail;
};

export const getLinkQuestion = () => {
  let mail = "mailto:atendimento@probrain.com.br";
  mail += "?subject=Preciso de Ajuda";
  mail += "&body=Olá,%0D%0DEstou com a seguinte dúvida.";
  if (Auth && Auth.user && Auth.user.attributes && Auth.user.attributes.email) {
    mail += "%0D%0DE-mail cadastrado: '" + Auth.user.attributes.email + "'.";
  }
  return mail;
};

export const getLinkWhatsapp = () => {
  let whatsapp = "https://api.whatsapp.com/send?phone=5515981500059&text=";
  whatsapp += "Olá! Eu gostaria de saber mais sobre o Afinando Plus";
  return whatsapp;
};

export const getLinkPurchase = () => {
  let mail = "mailto:atendimento@probrain.com.br";
  mail += "?subject=Preciso de Ajuda";
  mail += "&body=Olá,%0D%0DEu gostaria de um plano da seguinte forma.";
  if (Auth && Auth.user && Auth.user.attributes && Auth.user.attributes.email) {
    mail += "%0D%0DE-mail cadastrado: '" + Auth.user.attributes.email + "'.";
  }
  return mail;
};

export const getLinkAudioFoco = () => {
  let mail = "mailto:atendimento@probrain.com.br";
  mail += "?subject=Informações AudioFoco";
  mail += "&body=Olá,%0D%0DGostaria de mais informações sobre o AudioFoco.";
  if (Auth && Auth.user && Auth.user.attributes && Auth.user.attributes.email) {
    mail += "%0D%0DE-mail cadastrado: '" + Auth.user.attributes.email + "'.";
  }
  return mail;
};

export const isUserSingle = () => {
  let isSingle = false;
  if (Auth && Auth.user && Auth.user.attributes) {
    const user = Auth.user.attributes;
    const account_type = getUserAccountType(user[kApi.CUSTOM_PERMISSIONS]);
    isSingle =
      account_type === kApi.USERTYPE_SINGLE_FREE ||
      account_type === kApi.USERTYPE_SINGLE_PAID;
  }
  return isSingle;
};

export const isPaid = () => {
  let isPaid = false;
  if (Auth && Auth.user && Auth.user.attributes) {
    const user = Auth.user.attributes;
    const account_type = getUserAccountType(user[kApi.CUSTOM_PERMISSIONS]);
    isPaid =
      account_type === kApi.USERTYPE_PRO_PAID ||
      account_type === kApi.USERTYPE_SINGLE_PAID ||
      account_type === kApi.USERTYPE_FAMILY_PAID;
  }
  return isPaid;
};

export const getUserTypeToAbility = () => {
  let type = "free";
  if (Auth && Auth.user && Auth.user.attributes) {
    const user = Auth.user.attributes;
    const account_type = getUserAccountType(user[kApi.CUSTOM_PERMISSIONS]);
    if (account_type === kApi.USERTYPE_PRO_PAID) type = "fono";
    else if (isPaid()) type = "paid";
    // else if (checkIsFree()) type = "free";
    return type;
  }
};

/**
 * =======================================
 *      Checking Account by SubTypes
 * =======================================
 */

export const isParentPaid = () => isPaid() && !isUserSingle();

/**
 * =======================================
 *      Checking Account By Type
 * =======================================
 */

export const isProPaid = () => {
  let isPaid = false;
  if (Auth && Auth.user && Auth.user.attributes) {
    const user = Auth.user.attributes;
    const account_type = getUserAccountType(user[kApi.CUSTOM_PERMISSIONS]);
    isPaid = account_type === kApi.USERTYPE_PRO_PAID;
  }
  return isPaid;
};
export const isProFree = () => {
  let isPaid = false;
  if (Auth && Auth.user && Auth.user.attributes) {
    const user = Auth.user.attributes;
    const account_type = getUserAccountType(user[kApi.CUSTOM_PERMISSIONS]);
    isPaid = account_type === kApi.USERTYPE_PRO_FREE;
  }
  return isPaid;
};
export const isSinglePaid = () => {
  let isPaid = false;
  if (Auth && Auth.user && Auth.user.attributes) {
    const user = Auth.user.attributes;
    const account_type = getUserAccountType(user[kApi.CUSTOM_PERMISSIONS]);
    isPaid = account_type === kApi.USERTYPE_SINGLE_PAID;
  }
  return isPaid;
};

export const isSingleFree = () => {
  let isThisTipe = false;
  if (Auth && Auth.user && Auth.user.attributes) {
    const user = Auth.user.attributes;
    const account_type = getUserAccountType(user[kApi.CUSTOM_PERMISSIONS]);
    isThisTipe = account_type === kApi.USERTYPE_SINGLE_FREE;
  }
  return isThisTipe;
};
export const isFamilyPaid = () => {
  let isThisTipe = false;
  if (Auth && Auth.user && Auth.user.attributes) {
    const user = Auth.user.attributes;
    const account_type = getUserAccountType(user[kApi.CUSTOM_PERMISSIONS]);
    isThisTipe = account_type === kApi.USERTYPE_FAMILY_PAID;
  }
  return isThisTipe;
};

export const isFamilyFree = () => {
  let isThisTipe = false;
  if (Auth && Auth.user && Auth.user.attributes) {
    const user = Auth.user.attributes;
    const account_type = getUserAccountType(user[kApi.CUSTOM_PERMISSIONS]);
    isThisTipe = account_type === kApi.USERTYPE_FAMILY_FREE;
  }
  return isThisTipe;
};

//;Auth.user.attributes
// custom:permissions: "001000"
// email: "resbolar.resbolar+2@gmail.com"
// email_verified: true
// given_name: "Bala "
// sub: "c36bd369-adda-4d0c-a30f-760ee44ff5c3"

export function validarPatternEmail(providor) {
  const sugestoes = {
    "^g.*[ma].*l\.[^\s@]+$": "gmail.com",
    "^h.*[tm].*l\.[^\s@]+$": "hotmail.com",
    "^y.*[aho].*o\.[^\s@]+$": "yahoo.com.br",
    "^o.*[utlo].*k\.[^\s@]+$": "outlook.com",
    "^i.*[clou].*d\.[^\s@]+$": "icloud.com"
  };

  for (const regexPattern in sugestoes) {
      const regex = new RegExp(regexPattern);
      if (regex.test(providor) && sugestoes[regexPattern]!=providor) {
          return sugestoes[regexPattern];
      }
  }
  return false
}


export function validarPatternEmailProvidor(providor) {
  const sugestoes = {
      "^gmail\.":"gmail.com",
      "^hotmail\.":"hotmail.com",
      "^yahoo\.":"yahoo.com.br",
      "^outlook\.":"outlook.com",
      "^icloud\.":"icloud.com"
  };

  for (const regexPattern in sugestoes) {
    const regex = new RegExp(regexPattern);
    if (regex.test(providor) && sugestoes[regexPattern]!=providor) {
        return sugestoes[regexPattern];
    }
}
  
  return false
}
