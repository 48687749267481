import * as actionTypes from "./actionTypes";

// ============ PLANS ============

export const initFetchPlans = () => {
  return {
    type: actionTypes.PLANS_INIT,
  };
};

export const fetchPlansSuccess = (data) => {
  return {
    type: actionTypes.PLANS_SET,
    data: data,
  };
};

export const fetchPlansFail = (error) => {
  return {
    type: actionTypes.PLANS_FETCH_FAIL,
    error: error,
  };
};

// ============ PLAN CHANGE ============
// ONLY CHANGE TO PAY TO FREE
// OR FREE TO FREE
export const initFetchPlanChange = (product_id) => {
  return {
    type: actionTypes.PLAN_CHANGE_INIT,
    product_id: product_id,
  };
};

export const fetchPlanChangeSuccess = (data) => {
  return {
    type: actionTypes.PLAN_CHANGE_SET,
    data: data,
  };
};

export const fetchPlanChangeFail = (error) => {
  return {
    type: actionTypes.PLAN_CHANGE_FETCH_FAIL,
    error: error,
  };
};
