import { put } from "redux-saga/effects";
import axios from "../../axios/axios";
import * as actions from "../actions";
import * as kApi from "../../constants/api";
import { refreshTokenSaga } from "./LocalRefreshToken";
import { Auth } from "aws-amplify";

export function* initFetchSalesCardSaga(action) {
  try {
    const response = yield axios.get(
      kApi.API_URL_SALES_CARD + "/" + action.id,
      {
        headers: {
          Authorization: Auth.user.signInUserSession.idToken.jwtToken,
        },
      }
    );
    yield put(actions.fetchSalesCardSuccess(response.data));
  } catch (error) {
    try {
      const refreshToken = yield refreshTokenSaga();
      const response = yield axios.get(
        kApi.API_URL_SALES_CARD + "/" + action.id,
        {
          headers: {
            Authorization: refreshToken.signInUserSession.idToken.jwtToken,
          },
        }
      );
      yield put(actions.fetchSalesCardSuccess(response.data));
    } catch (error) {
      yield put(actions.fetchSalesCardFail(error));
    }
  }
}

export function* initFetchSalesCardsSaga(action) {
  try {
    const response = yield axios.get(kApi.API_URL_SALES_CARDS, {
      headers: {
        Authorization: Auth.user.signInUserSession.idToken.jwtToken,
      },
    });
    yield put(actions.fetchSalesCardsSuccess(response.data));
  } catch (error) {
    try {
      const refreshToken = yield refreshTokenSaga();
      const response = yield axios.get(kApi.API_URL_SALES_CARDS, {
        headers: {
          Authorization: refreshToken.signInUserSession.idToken.jwtToken,
        },
      });
      yield put(actions.fetchSalesCardsSuccess(response.data));
    } catch (error) {
      yield put(actions.fetchSalesCardsFail(error));
    }
  }
}
export function* initFetchSalesCouponsSaga(action) {
  try {
    const response = yield axios.get(kApi.API_URL_SALES_COUPONS, {
      headers: {
        Authorization: Auth.user.signInUserSession.idToken.jwtToken,
      },
    });
    yield put(actions.fetchSalesCouponsSuccess(response.data));
  } catch (error) {
    try {
      const refreshToken = yield refreshTokenSaga();
      const response = yield axios.get(kApi.API_URL_SALES_COUPONS, {
        headers: {
          Authorization: refreshToken.signInUserSession.idToken.jwtToken,
        },
      });
      yield put(actions.fetchSalesCouponsSuccess(response.data));
    } catch (error) {
      yield put(actions.fetchSalesCouponsFail(error));
    }
  }
}

export function* initFetchSalesOrderSaga(action) {
  try {
    const response = yield axios.get(
      kApi.API_URL_SALES_ORDER + "/" + action.state,
      {
        headers: {
          Authorization: Auth.user.signInUserSession.idToken.jwtToken,
        },
      }
    );
    yield put(actions.fetchSalesOrderSuccess(response.data));
  } catch (error) {
    try {
      const refreshToken = yield refreshTokenSaga();
      const response = yield axios.get(
        kApi.API_URL_SALES_ORDER + "/" + action.state,
        {
          headers: {
            Authorization: refreshToken.signInUserSession.idToken.jwtToken,
          },
        }
      );
      yield put(actions.fetchSalesOrderSuccess(response.data));
    } catch (error) {
      yield put(actions.fetchSalesOrderFail(error));
    }
  }
}

export function* initFetchSalesPagseguroSaga(action) {
  const requestBody = JSON.stringify({
    product_id: action.product_id,
    email: Auth.user.attributes.email,
    sub: Auth.user.attributes.sub,
  });
  try {
    const response = yield axios.post(
      kApi.API_URL_SALES_PAGSEGURO,
      requestBody,
      {
        headers: {
          Authorization: Auth.user.signInUserSession.idToken.jwtToken,
        },
      }
    );
    yield put(actions.fetchSalesPagseguroSuccess(response.data));
  } catch (error) {
    try {
      const refreshToken = yield refreshTokenSaga(action);
      const response = yield axios.post(
        kApi.API_URL_SALES_PAGSEGURO,
        requestBody,
        {
          headers: {
            Authorization: refreshToken.signInUserSession.idToken.jwtToken,
          },
        }
      );
      yield put(actions.fetchSalesPagseguroSuccess(response.data));
    } catch (error) {
      yield put(actions.fetchSalesPagseguroFail(error));
    }
  }

  Auth.currentAuthenticatedUser({ bypassCache: true });
}

export function* initFetchSalesCitiesByStateSaga(action) {
  try {
    const response = yield axios.get(
      kApi.API_URL_SALES_CITIES_BY_STATE + "/" + action.state,
      {
        headers: {
          Authorization: Auth.user.signInUserSession.idToken.jwtToken,
        },
      }
    );
    yield put(actions.fetchSalesCitiesByStateSuccess(response.data));
  } catch (error) {
    try {
      const refreshToken = yield refreshTokenSaga();
      const response = yield axios.get(
        kApi.API_URL_SALES_CITIES_BY_STATE + "/" + action.state,
        {
          headers: {
            Authorization: refreshToken.signInUserSession.idToken.jwtToken,
          },
        }
      );
      yield put(actions.fetchSalesCitiesByStateSuccess(response.data));
    } catch (error) {
      yield put(actions.fetchSalesCitiesByStateFail(error));
    }
  }
}
