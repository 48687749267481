// ================ ROUTES  =================
export const R_ROOT = "/ajuda";
export const R_SUPPORT = "/ajuda";
export const R_PANEL = "/painel";
export const R_SOLUCOES = "/produtos";
export const R_SOLUCOES_PROD = "/produtos/:any";
export const R_CONTENTS = "/conteudos";
export const R_CONTENTS_INFO = "/conteudos/:any/:any";

export const R_LOGIN = "/login";
export const R_ACCOUNT_SETTINGS = "/configuracoes-de-conta";
export const R_SEGURANCA = "/seguranca";
export const R_SEGURANCA_TERMOS_DE_USO = "/seguranca/termos-de-uso";
export const R_DOCUMENTOS = "/documentos";
export const R_ALL_PAYMENTS_PLANS = "/planos";
export const R_FIRST_ACCESS = "/primeiro-acesso";
export const R_ORDER_HISTORY = "/historico-de-pedidos";
export const R_PAYMENT_METHOD = "/formas-de-pagamento";
export const R_SHOPPING_CART = "/carrinho-de-compras/:any";
export const R_SHOPPING = "/carrinho-de-compras/";

export const R_PLAN_PAYMENT = "/planos/:any";
export const R_FAQ = "/faq";
export const R_PLANS_PATH = "/carrinho-de-compras/:any/eu";
export const GET_R_PLAN_PAYMENT = (id) => "/planos/" + id;
export const GET_R_SHOPPING_CART = (id) => "/carrinho-de-compras/" + id;
export const GET_R_PLANS_PATH = (id) => "/carrinho-de-compras/" + id + "/eu";


