import * as actionTypes from "./actionTypes";

// ============ SALES ============
// ============ CARD ============
export const initFetchSalesCard = (id) => {
  return {
    type: actionTypes.SALES_CARD_INIT,
    id: id,
  };
};

export const fetchSalesCardSuccess = (data) => {
  return {
    type: actionTypes.SALES_CARD_SET,
    data: data,
  };
};

export const fetchSalesCardFail = (error) => {
  return {
    type: actionTypes.SALES_CARD_FETCH_FAIL,
    error: error,
  };
};

// ============ CARDS ============

export const initFetchSalesCards = () => {
  return {
    type: actionTypes.SALES_CARDS_INIT,
  };
};

export const fetchSalesCardsSuccess = (data) => {
  return {
    type: actionTypes.SALES_CARDS_SET,
    data: data,
  };
};

export const fetchSalesCardsFail = (error) => {
  return {
    type: actionTypes.SALES_CARDS_FETCH_FAIL,
    error: error,
  };
};
// ============ COUPONS ============

export const initFetchSalesCoupons = () => {
  return {
    type: actionTypes.SALES_COUPONS_INIT,
  };
};

export const fetchSalesCouponsSuccess = (data) => {
  return {
    type: actionTypes.SALES_COUPONS_SET,
    data: data,
  };
};

export const fetchSalesCouponsFail = (error) => {
  return {
    type: actionTypes.SALES_COUPONS_FETCH_FAIL,
    error: error,
  };
};

// ============ ORDER ============

export const initFetchSalesOrder = (state) => {
  return {
    type: actionTypes.SALES_ORDER_INIT,
    state: state,
  };
};

export const fetchSalesOrderSuccess = (data) => {
  return {
    type: actionTypes.SALES_ORDER_SET,
    data: data,
  };
};

export const fetchSalesOrderFail = (error) => {
  return {
    type: actionTypes.SALES_ORDER_FETCH_FAIL,
    error: error,
  };
};

// =========PAGSEGURO ============

export const initFetchSalesPagseguro = (product_id) => {
  return {
    type: actionTypes.SALES_PAGSEGURO_INIT,
    product_id: product_id,
  };
};

export const fetchSalesPagseguroSuccess = (data) => {
  return {
    type: actionTypes.SALES_PAGSEGURO_SET,
    data: data,
  };
};

export const fetchSalesPagseguroFail = (error) => {
  return {
    type: actionTypes.SALES_PAGSEGURO_FETCH_FAIL,
    error: error,
  };
};

// ============ CITIES BY STATE ============

export const initFetchSalesCitiesByState = (state) => {
  return {
    type: actionTypes.SALES_CITIES_BY_STATE_INIT,
    state: state,
  };
};

export const fetchSalesCitiesByStateSuccess = (data) => {
  return {
    type: actionTypes.SALES_CITIES_BY_STATE_SET,
    data: data,
  };
};

export const fetchSalesCitiesByStateFail = (error) => {
  return {
    type: actionTypes.SALES_CITIES_BY_STATE_FETCH_FAIL,
    error: error,
  };
};
