import React from "react";
import classes from "./Footer.module.scss";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";
import { getLinkQuestion } from "../../helpers/sessionHelpers";

const Footer = () => {
  const { t } = useTranslation();
  let _content = null;
  // if (scrolled )
  _content = (
    <>
      <div className={classes.footerStyle}>
        <footer>
          {
            t("footer.name")
          }
        </footer>
        <footer className={classes.footerSpace}>
          {
             t("footer.address")
          }
        </footer>
        <footer>
          {t("footer.copyright")} &#169; {new Date().getFullYear()} ProBrain -{" "}
          {t("footer.all_rights_reserved")}
        </footer>
      </div>

      <a href={getLinkQuestion()} className={classes.ButtonHelp}>
      <FontAwesomeIcon className={classes.Icon} icon={faQuestion} />
      <span className={classes.tooltiptext}>{t("footer.help")}</span>
      </a>
    </>
  );
  return _content;
};

export default Footer;
