import * as actionTypes from "./actionTypes";

// ============ FETECH MY USER  ============
export const initFetchMyUser = () => {
  return {
    type: actionTypes.MY_USER_INIT,
  };
};

export const fetchMyUserSet = (data) => {
  return {
    type: actionTypes.MY_USER_SET,
    data: data,
  };
};

export const fetchMyUserFail = (error) => {
  return {
    type: actionTypes.MY_USER_FETCH_FAIL,
    error: error,
  };
};

// ============ UPDATE MY USER  ============
export const initUpdateMyUser = (data) => {
  return {
    type: actionTypes.UPDATE_MY_USER_INIT,
    data: data,
  };
};

export const updateMyUserSet = (data) => {
  return {
    type: actionTypes.UPDATE_MY_USER_SET,
    data: data,
  };
};

export const updateMyUserFail = (error) => {
  return {
    type: actionTypes.UPDATE_MY_USER_FETCH_FAIL,
    error: error,
  };
};
