import * as actionTypes from "./actionTypes";

// ============ PRODUCTS FREE ============
export const initFetchProducts = (product_type) => {
  return {
    type: actionTypes.PRODUCTS_INIT,
    product_type: product_type,
  };
};

export const fetchProductsSuccess = (data) => {
  return {
    type: actionTypes.PRODUCTS_SET,
    data: data,
  };
};

export const fetchProductsFail = (error) => {
  return {
    type: actionTypes.PRODUCTS_FETCH_FAIL,
    error: error,
  };
};
