import * as actionTypes from "./actionTypes";

// ============ USER ============

export const initFetchNotifications = (user_id) => {
  return {
    type: actionTypes.NOTIFICATIONS_INIT,
    user_id: user_id,
  };
};

export const fetchNotificationsSuccess = (notifications) => {
  return {
    type: actionTypes.NOTIFICATIONS_SET,
    notifications: notifications,
  };
};

export const fetchNotificationsFail = (error) => {
  return {
    type: actionTypes.NOTIFICATIONS_FETCH_FAIL,
    error: error,
  };
};
