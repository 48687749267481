import React, { useEffect, useState } from "react";
import "./App.scss";
import { initSessionActive } from "./store/actions";
import { isAuthenticated } from "./helpers";
import { Switch, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as routes from "./constants/routes";
import Amplify, { Auth } from "aws-amplify";
import asyncComponent from "./hoc/AsyncComponent";
import awsconfig from "./aws-exports";
import Layout from "./hoc/Layout";
// Containers that dont need a async loading
// They dont fetc data initialy
import { PIXEL_ID, GET_PROD_OR_DEV } from "./constants/api";
import { PROD } from "./constants/defaults";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "./axios/axios";
import * as kApi from "./constants/api";
import jwt_decode from "jwt-decode";
import Cookies from "universal-cookie";
import Crypto from "crypto-js";
import moment from "moment-timezone";
import { gtag } from "ga-gtag";

import {
  CognitoUserSession,
  CognitoIdToken,
  CognitoUser,
  CognitoRefreshToken,
  CognitoAccessToken,
} from "amazon-cognito-identity-js";

Amplify.configure(awsconfig);

function App() {
  const [, refreshState] = useState(false);
  const [structure, setStructure] = useState();
  const [forceByBifrost, setForceByBifrost] = useState(0);
  const dispatch = useDispatch();
  const { session } = useSelector((state) => state.session);

  const [urlParam, seturlParam] = useState();
  const [struct, setstruct] = useState();
  const [_routes, set_routes] = useState(false);

  gtag("set", "user_id", session?.sub);

  useEffect(() => {
    let url_string = window.location.href;
    let url = new URL(url_string);
    seturlParam(url.searchParams.get("u"));
    setstruct(url.searchParams.get("st"));

    console.log("try forceByBifrost", forceByBifrost);
    // Pixel ==========================
    // advancedMatching => optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    const advancedMatching = {};
    const options = {
      autoConfig: true, // set pixel's autoConfig
      // debug: true, // enable logs
    };

    const ReactPixel = require("react-facebook-pixel");
    ReactPixel.default.init(PIXEL_ID, advancedMatching, options);

    Auth.currentAuthenticatedUser()
      .then((cognitoUser) => {
        refreshState(true);
        if (!session || forceByBifrost == 1) {
          dispatch(initSessionActive(cognitoUser));
          setForceByBifrost(0);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dispatch, session, forceByBifrost]);

 

  const asyncSolutions = asyncComponent(() => {
    return import("./containers/Solutions");
  });

  const asyncFaq = asyncComponent(() => {
    return import("./containers/Faq");
  });

  const asyncPersonalInfo = asyncComponent(() => {
    return import("./containers/AccountSettings");
  });
  const asyncUseTerms = asyncComponent(() => {
    return import("./containers/UseTerms");
  });
  const asyncDocuments = asyncComponent(() => {
    return import("./containers/Documents");
  });
  const asyncAllPaymentsAndPlans = asyncComponent(() => {
    return import("./containers/Plans");
  });
  const asyncEditPlansPayment = asyncComponent(() => {
    return import("./containers/Plans/EditPlan");
  });
  const asyncPaymentMethod = asyncComponent(() => {
    return import("./containers/Plans/PaymentMethod");
  });
  const asyncOrderHistory = asyncComponent(() => {
    return import("./containers/Plans/OrderHistory");
  });
  const asyncFirstAccess = asyncComponent(() => {
    return import("./containers/FirstAccess");
  });
  const asyncLogin = asyncComponent(() => {
    return import("./containers/Login");
  });
  const asyncShoppingCart = asyncComponent(() => {
    return import("./containers/ShoppingCart");
  });

  const getLoginRoutesHandler = () => (
    <Switch>
      <Route path={routes.R_SOLUCOES} exact component={asyncSolutions} />
      <Route path={routes.R_LOGIN} exact component={asyncLogin} />
      <Route path="*" exact component={asyncLogin} />
      {/* <Redirect to={routes.R_LOGIN} /> */}
    </Switch>
  );

  const getRoutesHandler = () => (
    <Switch>
      <Route path={routes.R_SOLUCOES} component={asyncSolutions} />
      <Route path={routes.R_SOLUCOES_PROD} component={asyncSolutions} />
      <Route path={routes.R_FAQ} component={asyncFaq} />
      <Route
        path={routes.R_ACCOUNT_SETTINGS}
        exact
        component={asyncPersonalInfo}
      />
      <Route
        path={routes.R_SEGURANCA_TERMOS_DE_USO}
        exact
        component={asyncUseTerms}
      />
      <Route path={routes.R_DOCUMENTOS} exact component={asyncDocuments} />
      <Route
        path={routes.R_PLAN_PAYMENT}
        exact
        component={asyncEditPlansPayment}
      />
      <Route
        path={routes.R_PAYMENT_METHOD}
        exact
        component={asyncPaymentMethod}
      />
      <Route
        path={routes.R_ORDER_HISTORY}
        exact
        component={asyncOrderHistory}
      />
      <Route path={routes.R_FIRST_ACCESS} exact component={asyncFirstAccess} />
      <Route
        path={routes.R_SHOPPING_CART}
        exact
        component={asyncShoppingCart}
      />
      <Route path={routes.R_PLANS_PATH} exact component={asyncShoppingCart} />
      <Route
        path={routes.R_ALL_PAYMENTS_PLANS}
        exact
        component={asyncAllPaymentsAndPlans}
      />
      <Route path="*" exact component={asyncAllPaymentsAndPlans} />
      {/* ================================================ */}
      {/* <Redirect to={routes.R_ALL_PAYMENTS_PLANS} /> */}
    </Switch>
  );

  useEffect(() => {
    if (struct) setStructure(struct);
    if (urlParam) {
      Bifrost(urlParam);
    } else if (!_routes || isAuthenticated()){
      set_routes(
        isAuthenticated() ? getRoutesHandler() : getLoginRoutesHandler()
      );
    }
  }, [urlParam, struct, session]);

  try {
    if (GET_PROD_OR_DEV === PROD && window.location.protocol !== "https:") {
      window.location.replace(
        `https:${window.location.href.substring(
          window.location.protocol.length
        )}`
      );
    }
  } catch (error) {}

  function Bifrost(u) {
    const sKey = awsconfig.Heimdall;
    let AmazonCognitoIdentity = require("amazon-cognito-identity-js"),
      decoded,
      cookies;

    cookies = new Cookies();
    u = decodeURI(u);
    try {
      let newToken = Crypto.AES.decrypt(u, sKey);

      newToken = newToken.toString(Crypto.enc.Utf8);
      decoded = jwt_decode(newToken);
    } catch (err) {
      console.log("close bifrost");
      console.log(err);
      return (window.location.href = "/login");
    }

    if (!decoded || !("username" in decoded)) {
      return (window.location.href = "/login");
    }
    let dUsername = Crypto.SHA256(decoded.username, sKey);
    let cookieA = cookies.get(dUsername + "A");
    let cookieI = cookies.get(dUsername + "I");
    let cookieR = cookies.get(dUsername + "R");
    if (!cookieA || !cookieI || !cookieR) {
      console.log("redirect login");
      //return (window.location.href = "/login");
    }
    let newTokens = [
      Crypto.AES.decrypt(cookieA, sKey).toString(),
      Crypto.AES.decrypt(cookieI, sKey).toString(),
      cookieR,
    ];

    const AccessToken = new CognitoAccessToken({ AccessToken: newTokens[0] }),
      IdToken = new CognitoIdToken({ IdToken: newTokens[1] }),
      RefreshToken = new CognitoRefreshToken({ RefreshToken: newTokens[2] });

    const sessionData = {
        IdToken: IdToken,
        AccessToken: AccessToken,
        RefreshToken: RefreshToken,
      },
      userSession = new CognitoUserSession(sessionData);

    let poolData = {
        UserPoolId: awsconfig.userPoolId,
        ClientId: awsconfig.userPoolWebClientId,
        OAuth: awsconfig.oauth,
      },
      userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData),
      userData = {
        Username: decoded.username,
        Pool: userPool,
      };
    console.log("work bifrost");
    const cognitoUser = new CognitoUser(userData);
    cognitoUser.setSignInUserSession(userSession);

    cookies.remove(dUsername + "A", { path: "/" });
    cookies.remove(dUsername + "I", { path: "/" });
    cookies.remove(dUsername + "R", { path: "/" });
    let url_string = window.location.href;
    let url = new URL(url_string);
    let params = new URLSearchParams(url.search);
    params.delete("u");
    window.history.replaceState(null, "", "?" + params + url.hash);
    setForceByBifrost(1);
  }

  useEffect(() => {
    const cur_date = moment().utc().format("DD/MM/YYYY");
    axios
      .get(kApi.API_URL_CACHE_VERSION, {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((res) => {
        const cur_version = res.data.version;
        const clearCache = () => {
          if ("caches" in window) {
            caches.keys().then((names) => {
              // Delete all the cache files
              names.forEach((name) => {
                caches.delete(name);
              });
            });
          }

          localStorage.setItem("cacheFront", btoa(cur_date));
          localStorage.setItem("cacheFrontVersion", cur_version);
          window.history.forward(1);
          window.location.reload(true);
        };
        const cacheFrontDate = localStorage.getItem("cacheFront");
        const cacheFrontVersion = localStorage.getItem("cacheFrontVersion");
        if (!(cacheFrontDate || cacheFrontVersion)) {
          clearCache();
        } else {
          const cache_date = atob(cacheFrontDate);
          console.log("cur_date", cur_date);
          console.log("cache_date", cache_date);
          console.log("cur_version", cur_version);
          console.log("cacheFrontVersion", cacheFrontVersion);
          if (cur_date != cache_date) clearCache();
          if (cacheFrontVersion != cur_version) clearCache();
        }
      });
  }, []);

  return (
    <div className="App">
      <Layout structure={structure}>{_routes}</Layout>
    </div>
  );
}

export default App;
