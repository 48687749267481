import * as kUserException from "../constants/users_login";

export const getMsgErrorLogin = (error_code) => {
  let msg = "";
  switch (error_code) {
    // Login
    case kUserException.NOT_AUTHORIZED_EXCEPTION:
      msg = "msg_error.NotAuthorizedException";
      break;
    // Verification Code
    case kUserException.CODE_MISMATCH_EXCEPTION:
      msg = "msg_error.CodeMismatchException";
      break;
    case kUserException.USER_NOT_CONFIRMED_EXCEPTION:
      msg = "msg_error.UserNotConfirmedException";
      break;
    // singup
    case kUserException.INVALID_PASSWORD_EXCEPTION:
      msg = "msg_error.InvalidPasswordException";
      break;
    case kUserException.INVALID_PARAMETER_EXCEPTION:
      msg = "msg_error.InvalidParameterException";
      break;
    case kUserException.USERNAME_EXISTS_EXCEPTION:
      msg = "msg_error.UsernameExistsException";
      break;
    case kUserException.LIMIT_EXCEEDED_EXCEPTION:
      msg = "msg_error.LimitExceededException";
      break;
    default:
      msg = "msg_error.unidentifiedException";
      break;
  }
  return msg;
};
