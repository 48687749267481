/**
 *  Using routes, the component will be downloaded only if the user clicks on a link
 *  in home page that displays the desired page.
 * 
 * Rear more:
 * https://medium.com/front-end-weekly/loading-components-asynchronously-in-react-app-with-an-hoc-61ca27c4fda7
 *  or google it as: asyncComponent in  react
 */

/**
 * HOW TO USE?
 * 
 * instead import <...> as "...";
 * const component_name = asyncComponent(() => import('../component_path'))
 */

import React, { Component } from 'react';

const asyncComponent = (importComponent) => {
    return class extends Component {
        state = {
            component: null
        }

        componentDidMount () {
            importComponent()
                .then(cmp => {
                    this.setState({component: cmp.default});
                });
        }
        
        render () {
            const C = this.state.component;
            return C ? <C {...this.props} /> : null;
        }
    }
}

export default asyncComponent;