import React from "react";
import classes from "./Spinner.module.scss";
import SpinnerGif2 from "../../../assets/images/spinner_purple.gif";

const Spinner = ({ small = null, center = null }) => {
  let finalClass = classes.SpinnerArea;
  if (small) finalClass += " " + classes.Small;
  if (center) finalClass += " " + classes.Center;
  return (
    <div className={finalClass}>
      <img src={SpinnerGif2} alt="loading..." className={classes.Spinner} />
    </div>
  );
};

export default Spinner;
