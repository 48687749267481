import * as defaults from "./constants/defaults";
import { enviroment } from "./build_enviroment";

// -----------------------
//       next-dev DEV
// -----------------------
const config_dev = {
  userPoolId: "us-east-1_4CH8VE67K",
  region: "us-east-1",
  userPoolWebClientId: "31nkjmuco3hf3032ri82arcn2q",
  oauth: {
    domain: "auth.afinando.com.br/",
    redirectSignIn:
      "https://probraindev.afinandoocerebro.com.br/login?addlogin=true",
    redirectSignOut:
      "https://probraindev.afinandoocerebro.com.br/login?addlogin=true",
    // redirectSignIn: "https://nextdev.afinandoocerebro.com.br/login?addlogin=true",
    // redirectSignOut: "https://nextdev.afinandoocerebro.com.br/login?addlogin=true",
    responseType: "code",
  },
};

// -----------------------
//       next-dev PROD
// -----------------------
const config_prod = {
  userPoolId: "us-east-1_BFgpppTHT",
  region: "us-east-1",
  userPoolWebClientId: "227p04lnatr02u8svubl0fpl76",
  oauth: {
    domain: "auth.afinandoocerebro.com.br/",
    redirectSignIn: "https://probrain.afinandoocerebro.com.br/login",
    redirectSignOut: "https://probrain.afinandoocerebro.com.br/login",
    responseType: "code",
  },
};



export const RUNNING_ENVIROMENT = enviroment;

const config = enviroment === defaults.DEV ? config_dev : config_prod;
config["Heimdall"] = config.userPoolWebClientId + "Ddak#eir&D8AFukAFSD";

export default config;
