// ========================================
// ================== V3 ==================
// ========================================

import { Auth } from "aws-amplify";

export function* refreshTokenSaga() {
  const cognitoUser = yield Auth.currentAuthenticatedUser();
  const currentSession = yield Auth.currentSession();
  yield cognitoUser.refreshSession(currentSession.refreshToken);
  return cognitoUser;
}
