export const checkCPF = (strCPF) => {
  let soma;
  let resto;
  soma = 0;

  // if (!/[0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}/.test(strCPF)) return false;
  // strCPF = strCPF = strCPF.replace(/[^\d]+/g, "");
  if (strCPF === "00000000000") return false;

  for (let i = 1; i <= 9; i++)
    soma = soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(strCPF.substring(9, 10))) return false;

  soma = 0;
  for (let i = 1; i <= 10; i++)
    soma = soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(strCPF.substring(10, 11))) return false;
  // console.log(strCPF);
  return true;
};

export const checkCNPJ = (cnpj) => {
  cnpj = cnpj.replace(/[^\d]+/g, "");

  if (cnpj === "") return false;

  if (cnpj.length !== 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj === "00000000000000" ||
    cnpj === "11111111111111" ||
    cnpj === "22222222222222" ||
    cnpj === "33333333333333" ||
    cnpj === "44444444444444" ||
    cnpj === "55555555555555" ||
    cnpj === "66666666666666" ||
    cnpj === "77777777777777" ||
    cnpj === "88888888888888" ||
    cnpj === "99999999999999"
  )
    return false;

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;

  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }

  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado.toString() !== digitos.charAt(0).toString()) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado.toString() !== digitos.charAt(1).toString()) return false;

  return true;
};

export const cpfMask = (input = "") => {
  if (input) {
    input = input
      // replace whatever is not a number to ""
      // take the next two groups of inputs, putting a dot after the third inputs
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      // take the next two groups of inputs, putting a dot after the third inputs
      .replace(/(\d{3})(\d)/, "$1.$2")
      // take the next two groups of inputs, putting a "-" after the third inputs
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      // take the next two entries and do not let more
      .replace(/([0-9.-]]{14})(\d)/, "$1");
  }
  return input;
};

export const cpnjMask = (input = "") => {
  if (input) {
    input = input
      .replace(/\D/g, "") // replace whatever is not a number to ""
      .replace(/(\d{2})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1/$2")
      .replace(/(\d{4})(\d{1,2})/, "$1-$2")
      .replace(/([0-9.-/]]{19})\d+?$/, "$1");
  }
  return input;
};

export const regexNumber = (input = "") => {
  return input.replace(/[^0-9.()+-]/, "");
};

export const regexDocFiscal = (input = "") => {
  return input.replace(/[^0-9.-]/, "");
};

export const phoneMask = (input = "") => {
  input = input
    ? input
        // replace whatever is not a number to ""
        .replace(/\D/g, "")
        // take the next 2 inputs, putting it inside of brackets with a blank space at right of ')'
        .replace(/(\d{2})(\d)/, "($1) $2")
        // take the next 4 or 5 numbers  in the middle to apply next info
        .replace(/(\d{4,5})(\d{4})/, "$1-$2")
        // take the next four entries, put a "-" before and do not let more
        .replace(/(-\d{4})\d+?$/, "$1")
    : input;
  return input;
};

export const cepMask = (input = "") => {
  if (input)
    input = input
      .replace(/\D/g, "") // replace whatever is not a number to ""
      .replace(/(\d{5})(\d)/, "$1-$2")
      .replace(/([0-9-/]]{9})\d+?$/, "$1");

  return input;
};

export const dateMask = (input = "") => {
  return input
    ? input
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "$1/$2")
        .replace(/(\d{2})(\d)/, "$1/$2")
        .replace(/(\d{4})(\d)/, "$1")
    : input;
};

export const dateMaskMMyyyy = (input = "") => {
  return input
    ? input
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "$1/$2")
        .replace(/(\d{4})(\d)/, "$1")
    : input;
};

export const dateMaskDMY = (input = "") => {
  return input
    ? input.replace(
        "/^([0]?[1-9]|[1|2][0-9]|[3][0|1])[./-]([0]?[1-9]|[1][0-2])[./-]([0-9]{4}|[0-9]{2})$",
        ""
      )
    : input;
};

export const getRegExpEmail = () =>
  '/^(([^<>()[]\\.,;:s@"]+(.[^<>()[]\\.,;:s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/';

export const getRegExpData = () =>
  "/^([0]?[1-9]|[1|2][0-9]|[3][0|1])[./-]([0]?[1-9]|[1][0-2])[./-]([0-9]{4}|[0-9]{2})$";
