import { updateObject } from "../../helpers/index";
import * as actionTypes from "../actions/actionTypes";

const initialState = {
  plans: null,
  plans_error: null,
  plan_change: null,
  plan_change_error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PLANS_SET:
      return fetchPlansSuccess(state, action);
    case actionTypes.PLANS_FETCH_FAIL:
      return fetchPlansFail(state, action);
    case actionTypes.PLAN_CHANGE_SET:
      return fetchPlanChangeSuccess(state, action);
    case actionTypes.PLAN_CHANGE_FETCH_FAIL:
      return fetchPlanChangeFail(state, action);
    default:
      return state;
  }
};

// =========== My Plans
const fetchPlansSuccess = (state, action) => {
  return updateObject(state, {
    plans: action.data,
  });
};

const fetchPlansFail = (state, action) => {
  return updateObject(state, {
    plans_error: action.error,
  });
};

// =========== Plan Change
const fetchPlanChangeSuccess = (state, action) => {
  return updateObject(state, {
    plan_change: action.data,
  });
};

const fetchPlanChangeFail = (state, action) => {
  return updateObject(state, {
    plan_change_error: action.error,
  });
};

export default reducer;
