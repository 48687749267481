import * as defaults from "./defaults";
import * as aws from "../aws-exports";

// ==============================
// ========= ENVIROMENT =========
// ==============================

export const GET_PROD_OR_DEV = aws.RUNNING_ENVIROMENT;

if (GET_PROD_OR_DEV === defaults.DEV) {
  console.log("###############################");
  console.log("######  DEVELOPMENT MODE ######");
  console.log("###############################");
  console.log("--- !!!!!!! CHANGE: !!!!!!! ---");
  console.log("-------- aws-export.js --------");
  console.log("--- !!!!!!! TO PROD !!!!!!! ---");
  console.log("###############################");
  console.log("###############################");
}

// ================================
// ===== APIs GOOGLE ANALITCS =====
// ================================

//  Analytics salvos nos emails @atendimento...
const MAP_GOOGLE_ANALITICS = {
  [defaults.PROD]: "UA-163644675-3",
  [defaults.DEV]: "UA-163644675-4",
};
export const GOOGLE_ANALITICS = MAP_GOOGLE_ANALITICS[GET_PROD_OR_DEV];


// RECAPTHCA V2
const SECRET_reCAPTCHA_DEV = "6Ld8K-ApAAAAADFPrpE2eRRnBnz1kWLrM_szk_F4"
const SECRET_reCAPTCHA_PROD = "6LeQK-ApAAAAAPs1MmR9qFIFIe44XH_Ip_1Z72o3"

export const SECRET_reCAPTCHA=GET_PROD_OR_DEV === defaults.DEV ?SECRET_reCAPTCHA_DEV:SECRET_reCAPTCHA_PROD



// Pixel - Facebook
const MAP_PIXEL_ID = {
  [defaults.PROD]: "1033654857083937", // Pixel salvo no emails atendimento@...
  [defaults.DEV]: "1035549276918007", // Pixel salvo no emails tota.braz@...
};
export const PIXEL_ID = MAP_PIXEL_ID[GET_PROD_OR_DEV];
export const API_PIXEL =
  "https://graph.facebook.com/v11.0/1033654857083937/events?access_token=EAAFb7lLWGtIBALaBSAqXKC6FVYmShWNBPYyhwZAqQlDpZCmwZA2ykABLJfmPi6OwnNHDhyzK2DP01iUQnDY7kzpxFJY3XXtZBFZChlhyxneDXUHMqvZCsDUOuc1ihqhKqYr8GPMsNqr5nz67TEtS5sZAhKWGVNIOVGbDsTGvXxT0ZCCxZCtCy0LMY";
export const API_RD_STATION =
  "https://api.rd.services/platform/conversions?api_key=4ce4d47361e9280cd64b560e3578ec97";

//  EBANX salvos nos emails @atendimento...
//SANDBOXKEY = BPqWvjCk8xA_PGpTmym3y3drssm1JWI06BBlYcJKIbY
//PRODKEY= 88fCOAjprGMuR1kf-qVVmOHxczQGZCsQUt6EghtL2So

const MAP_EBANX_IDS = {
  [defaults.PROD]: "88fCOAjprGMuR1kf-qVVmOHxczQGZCsQUt6EghtL2So",
  [defaults.DEV]: "BPqWvjCk8xA_PGpTmym3y3drssm1JWI06BBlYcJKIbY",
};
export const EBANX_ID = MAP_EBANX_IDS[GET_PROD_OR_DEV];

// Amplifique.me
const MAP_AMPLIFIQUE_ME_ID = {
  [defaults.PROD]: "6006ee801dae85000e1a02ed",
  [defaults.DEV]: "600ad45da17f67000ebf6ac7",
};
export const AMPLIFIQUE_ME_ID = MAP_AMPLIFIQUE_ME_ID[GET_PROD_OR_DEV];

// ==============================
// ========= BASE APIs ==========
// ==============================

const API_URL_DEV =
  "https://k9357p16ih.execute-api.us-east-1.amazonaws.com/next-dev-2";
const API_URL_PROD =
  "https://mcqj5lejdg.execute-api.us-east-1.amazonaws.com/v1";
const PLUS_API_URL_DEV = "https://hafinandoplus.probrain.com.br/modules";
const PLUS_API_URL_PROD = "https://afinandoplus.probrain.com.br/modules";
const STATIC_API_DEV = "https://nextdev.afinandoocerebro.com.br";
const STATIC_API_PROD = "https://novo.afinandoocerebro.com.br";

const STATIC_API_AFINANDOPLUS_DEV = "https://hafinandoplus.probrain.com.br";
const STATIC_API_AFINANDOPLUS_PROD = "https://afinandoplus.probrain.com.br";
export const STATIC_PROBRAIN_URL = "https://probrain.afinandoocerebro.com.br";

// ===============================
// ======= APIs ENVIROMENT =======
// ===============================

// API URL to use
const API_URL = GET_PROD_OR_DEV === defaults.DEV ? API_URL_DEV : API_URL_PROD;
const PLUS_API_URL =
  GET_PROD_OR_DEV === defaults.DEV ? PLUS_API_URL_DEV : PLUS_API_URL_PROD;

// Static API URL to use
export const STATIC_API_AFINANDO =
  GET_PROD_OR_DEV === defaults.DEV ? STATIC_API_DEV : STATIC_API_PROD;
export const STATIC_API_AFINANDOPLUS =
  GET_PROD_OR_DEV === defaults.DEV
    ? STATIC_API_AFINANDOPLUS_DEV
    : STATIC_API_AFINANDOPLUS_PROD;

// ===============================
// ========= Statics URLS ========
// ===============================

export const STATIC_FILE_USE_TERMS =
  "https://termos.probrain.com.br/pub/terms/terms_2022.pdf";
export const STATIC_FILE_SECURITY_POLICY =
  "https://www.audbility.com.br/_files/ugd/cd0d44_f01a8753e2e647429e39e9bdb3ada60b.pdf";
// export const STATIC_FILE_USE_TERMS =
//   STATIC_API_AFINANDO + "/content/legal/termos-de-uso.pdf";

export const STATIC_FILE_PRIV_POLITICS =
  "https://termos.probrain.com.br/pub/policies/policies_2022.pdf";
// export const STATIC_FILE_PRIV_POLITICS =
//   STATIC_API_AFINANDO + "/content/legal/politicas-de-privacidade.pdf";

// ===============================
// ========== APIs URLS ==========
// ===============================

// -------------- STATIC --------------
export const API_URL_SERVICES_TERMS = API_URL + "/static/terms";
// export const BASE_API_URL_USER = API_URL + "/v1/users/user/user_pro_paid";
export const API_URL_STATIC_OPTIONS = API_URL + "/content/options";

// -------------- PIX --------------
export const API_URL_PIX = API_URL + "/pix";
export const API_URL_EMAIL_PIX = API_URL + "/emailsend";
// export const GET_API_URL_PIX = (sub) => API_URL + `/sales/order/${sub}`;
export const API_URL_GET_ORDER = (id) => API_URL + `/sales/order/${id}`;

// -------------- PLANS --------------
export const API_URL_PLANS = API_URL + "/plans";
export const API_URL_ALL_PAYMENTS = API_URL + "/plan/last-payment";
export const API_URL_IS_PAID = API_URL + "/plans/ispaid  ";
export const API_URL_PLAN_CHANGE = API_URL + "/plan/change";
// export const GET_API_URL_PLAN_BY_ID = (planId) => API_URL + "/plans" + planId;

// -------------- SALES --------------
export const API_URL_SALES_CARD = API_URL + "/sales/card";
export const API_URL_SALES_CARDS = API_URL + "/sales/cards";
export const API_URL_SALES_COUPONS = API_URL + "/sales/cupons";
export const API_URL_SALES_ORDER = API_URL + "/sales/process-order";
export const API_URL_SALES_VALIDATION_CHECKOUT =
  API_URL + "/sales/validation-checkout";
export const API_URL_SALES_PAGSEGURO = API_URL + "/sales/pagseguro";
export const API_URL_SALES_CITIES_BY_STATE = API_URL + "/sales/cities";
export const API_URL_SALES_PLUS = PLUS_API_URL + "/update_user_afinando.php"; //body: {email: ...@..., status: ativo || inativo}
export const API_URL_SHOW_ISPLUS = PLUS_API_URL + "/login.php"; //body: {email: ...@...}
export const API_URL_SALES_UPDATE = API_URL + "/sales/update";
export const API_POST_AUTH_TRANSPARENT = API_URL + "/user/transparent-auth";

// -------------- PRODUCTS --------------
export const API_URL_PRODUCTS = API_URL + "/products";

// -------------- PATIENTS --------------
export const API_URL_PATIENTS_PURGE = API_URL + "/patients/purge";

export const API_URL_USER = API_URL + "/user";

// -------------- EXTERNAL CALLS --------------
export const API_PDF_CALL = (id, sub) =>
  GET_PROD_OR_DEV === defaults.DEV
    ? `https://aaivhfqx2uqdrjgvomg2eayizy0citsu.lambda-url.us-east-1.on.aws/${id}?sub=${sub}`
    : `https://3whpcocdknk7bmpqrjkxmvk7fy0plvht.lambda-url.us-east-1.on.aws/${id}?sub=${sub}`;

export const GET_API_URL_USER = (user_id) =>
  API_URL + `/v1/users/user/${user_id}`;
// export const API_URL_USER = "/v1/users/user/user_pro_single";

export const GET_API_URL_NOTIFICATIONS = (user_id) =>
  API_URL + `/v1/notifications/${user_id}/notifications`;

//--------------- VERSIONAMENT ----------------------------

export const API_URL_CACHE_VERSION = "/version.json";
